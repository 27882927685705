import React, { useState, useEffect, Fragment } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import {
	handleLogIn,
	ipLookUp,
	checkIfBanned,
	sendReset,
	oAuthSignIn,
} from "../services/auth-requests";
import "./Auth.scss";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import arrow_black from "../images/arrow-blk.png";
import arrow_yellow from "../images/arrow-yellow.png";

const Login = () => {
	const recaptchaRef = React.createRef();
	const [failures, setFailures] = useState(0);
	const [captchaMessage, setCaptchaMessage] = useState("");
	const alert = useAlert();
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const closeForgotPWModal = () => setShowForgotPassword(false);
	const openForgotPWModal = () => setShowForgotPassword(true);

	const onCaptchaChange = (value) => {
		if (value) {
			setCaptchaMessage("");
		}
	};

	const oAuthLogin = async (
		email,
		id,
		accessToken,
		method,
		city,
		region,
		country,
		addressIP
	) => {
		const referrer = window.localStorage.getItem("referrer");
		const isBanned = await checkIfBanned();
		if (isBanned.data.banned) {
			alert.error("Account or IP has been banned");
			return;
		}
		return oAuthSignIn(
			email,
			accessToken,
			city,
			region,
			country,
			addressIP,
			referrer,
			method
		).then((result) => {
			if (result.message === "User hasn't agreed to terms yet") {
				window.location.href = `/terms-and-conditions?user=${email}&id=${id}&method=${method}`;
			} else if (result.success) {
				window.location.href = "/dashboard";
			} else {
				alert.error(result.message);
			}
		});
	};
	const responseFacebook = (response) => {
		console.log(response);
		if (response.email) {
			ipLookUp()
				.then((data) => {
					const { city, country_name, region } = data;
					const addressIP = data.ip;
					oAuthLogin(
						response.email,
						response.id,
						response.accessToken,
						"facebook",
						city,
						region,
						country_name,
						addressIP
					);
				})
				.catch((err) => {
					oAuthLogin(
						response.email,
						response.id,
						response.accessToken,
						"facebook"
					);
				});
		} else {
			console.log(response);
		}
	};

	const responseGoogle = (response) => {
		console.log(response.tokenObj);
		if (response.profileObj) {
			ipLookUp()
				.then((data) => {
					const { city, country_name, region } = data;
					const addressIP = data.ip;
					oAuthLogin(
						response.profileObj.email,
						response.googleId,
						response.tokenObj.access_token,
						"google",
						city,
						region,
						country_name,
						addressIP
					);
				})
				.catch((err) => {
					oAuthLogin(
						response.profileObj.email,
						response.googleId,
						response.tokenObj.access_token,
						"google"
					);
				});
		} else {
			console.log(response);
		}
	};

	return (
		<Fragment>
			<div className="auth-container">
				<div className="bg-image login" />
				<h1 className="auth-form-h1">Login</h1>
				<div className="first halves">
					<div className="left-half">
						<Formik
							initialValues={{
								email: "",
								password: "",
								agree: "",
								adult: "",
							}}
							validate={(values) => {
								let errors = {};
								if (!values.email) {
									errors.email = "Required";
								} else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
										values.email
									)
								) {
									errors.email = "Invalid email address";
								}

								if (!values.password) {
									errors.password = "Required";
								}
								if (failures >= 3) {
									const recaptchaValue = recaptchaRef.current.getValue();
									if (!recaptchaValue) {
										setCaptchaMessage(
											"Captcha is required"
										);
									}
								}
								return errors;
							}}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={async (values, { setSubmitting }) => {
								const { email, password } = values;
								const logInFn = (
									city,
									region,
									country,
									addressIP
								) => {
									return handleLogIn(
										email,
										password,
										city,
										region,
										country,
										addressIP
									).then((result) => {
										if (result === "Invalid Credentials") {
											alert.error(result);
											setFailures(failures + 1);
											setSubmitting(false);
										} else if (
											result ===
											"This user has been banned"
										) {
											alert.error(result);
											setFailures(failures + 1);
											setSubmitting(false);
										} else if (
											result ===
											"It appears there is a Google or Facebook account associated with this email. Please log in using that method or register to link your account to this email."
										) {
											alert.error(result);
											setSubmitting(false);
										} else {
											window.location.href = "/dashboard";
											setSubmitting(false);
										}
									});
								};
								// CHANGE FOR HEROKU:
								try {
									const ipData = await ipLookUp();
									const {
										city,
										country_name,
										region,
										ip,
									} = ipData;
									const isBanned = await checkIfBanned(ip);
									if (isBanned.data.banned) {
										alert.error(
											"Account or IP has been banned"
										);
										setSubmitting(false);
										return;
									}
									await logInFn(
										city,
										region,
										country_name,
										ip
									);
								} catch (err) {
									logInFn();
								}
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
							}) => (
								<Form noValidate onSubmit={handleSubmit}>
									<h2 className="auth-form-heading">
										with your Email
									</h2>
									<Form.Group
										className="form-group-custom"
										controlId="formBasicEmail"
									>
										<div className="label-container">
											<Form.Label>
												Email address
											</Form.Label>
											<div className="errors">
												{errors.email}
											</div>
										</div>
										<Form.Control
											className="auth-text-input"
											type="text"
											name="email"
											value={values.email}
											onChange={handleChange}
											isInvalid={!!errors.email}
											placeholder="Enter email"
										/>
									</Form.Group>
									<Form.Group
										className="form-group-custom"
										controlId="formBasicPassword"
									>
										<div className="label-container">
											<Form.Label>Password</Form.Label>
											<div
												className="forgot-pw-link"
												onClick={openForgotPWModal}
											>
												Forgot Password?
											</div>
										</div>
										<Form.Control
											className="auth-text-input"
											type="password"
											name="password"
											value={values.password}
											onChange={handleChange}
											isInvalid={!!errors.password}
											placeholder="Password"
										/>
									</Form.Group>
									{
										//failures >= 3 ? (
										// 	<div>
										// 		<ReCAPTCHA
										// 			ref={recaptchaRef}
										// 			sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
										// 			onChange={onCaptchaChange}
										// 		/>
										// 		<div className="input-feedback captcha-feedback">
										// 			{captchaMessage}
										// 		</div>
										// 	</div>
										// ) : null
									}
									<div className="form-submit-container">
										<button
											className="relative-btn"
											type="submit"
										>
											<span className="text">Login</span>
											<span className="arrow">
												<img src={arrow_black} />
											</span>
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
					<div className="right-half">
						<h2 className="auth-form-heading">OR...</h2>
						<FacebookLogin
							appId="1197671610420193"
							callback={responseFacebook}
							fields="name,email"
							isMobile={false}
							disableMobileRedirect={true}
							render={(renderProps) => (
								<button
									className="oauth-btn fb-login relative-btn"
									onClick={renderProps.onClick}
								>
									<span className="text">
										Login with Facebook
									</span>
									<span className="arrow">
										<img src={arrow_black} />
									</span>
								</button>
							)}
						/>
						<GoogleLogin
							clientId="677427210058-lp41b6d082s34cdb46qfjf83u234kunq.apps.googleusercontent.com"
							render={(renderProps) => (
								<button
									className="oauth-btn google-login relative-btn"
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
								>
									<span className="text">
										Login with Google/Youtube
									</span>
									<span className="arrow">
										<img src={arrow_black} />
									</span>
								</button>
							)}
							autoLoad={false}
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={"single_host_origin"}
						/>
					</div>
				</div>
				<div className="second halves">
					<div className="left-half links">
						<Link to="/copyright-policy">
							contributor agreement
						</Link>
						<Link to="/terms-of-service">terms of service</Link>
						<Link to="/privacy-policy">privacy policy</Link>
					</div>
					<div className="right-half sign-up-container">
						<span>Not a part of Yoon?</span>
						<Link to="/register">
							<div className="register-login-link relative-btn">
								<span className="text">Sign Up Now +++++</span>
								<span className="arrow">
									<img src={arrow_yellow} />
								</span>
							</div>
						</Link>
					</div>
				</div>
			</div>
			<Modal
				show={showForgotPassword}
				onHide={closeForgotPWModal}
				backdropClassName="forgot-pw-backdrop"
			>
				<Modal.Header closeButton>
					<Modal.Title className="auth-form-heading">
						Forgot Password
					</Modal.Title>
				</Modal.Header>
				<Formik
					initialValues={{
						email: "",
					}}
					validate={(values) => {
						let errors = {};
						if (!values.email) {
							errors.email = "Required";
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
								values.email
							)
						) {
							errors.email = "Invalid email address";
						}
						return errors;
					}}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={(values, { setSubmitting }) => {
						const { email } = values;
						console.log(values);
						sendReset(email).then((res) => {
							setSubmitting(false);
							closeForgotPWModal();
							if (res.error) {
								alert.error(res.error);
							} else {
								alert.success(res.message);
							}
						});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Modal.Body>
								<Form.Group controlId="formBasicEmail">
									<div className="label-container">
										<Form.Label>Email address</Form.Label>
										<div className="errors">
											{errors.email}
										</div>
									</div>
									<Form.Control
										className="auth-text-input"
										type="text"
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={!!errors.email}
										placeholder="Enter email"
									/>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer>
								<div className="form-submit-container">
									<button
										className="relative-btn"
										type="submit"
										disabled={isSubmitting}
									>
										<span className="text">Submit</span>
										<span className="arrow">
											<img src={arrow_black} />
										</span>
									</button>
								</div>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
		</Fragment>
	);
};

export default Login;
