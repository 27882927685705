import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { submitReset } from "../services/auth-requests";
import "./Auth.scss";
import { useAlert } from "react-alert";
import arrow_black from "../images/arrow-blk.png";

const ResetForm = props => {
	console.log(props);
	const { id } = props.match.params;
	const alert = useAlert();
	return (
		<div className="auth-container single-form">
			<div className="bg-image login" />
			<div className="toc-form">
				<Formik
					initialValues={{
						password: "",
						confirm: ""
					}}
					validate={values => {
						let errors = {};
						const { password, confirm } = values;
						const hasNumbers = /\d/.test(password);
						const hasNonalphas = /\W/.test(password);

						if (
							password.length < 6 ||
							!(hasNumbers || hasNonalphas)
						) {
							errors.password =
								"Password must be at least 6 characters and contain either a number or a special character.";
						}
						if (!password) {
							errors.password = "Required";
						}

						if (confirm !== password) {
							errors.confirm = "Passwords must match";
						}
						return errors;
					}}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={async (values, { setSubmitting }) => {
						const { password } = values;
						submitReset(password, id).then(res => {
							if (res.includes("Error")) {
								setSubmitting(false);
								alert.error(res);
							} else {
								alert.success(res);
								setSubmitting(false);
								setTimeout(props.history.push("/login"), 1500);
							}
						});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<h2
								className="auth-form-heading"
								style={{ marginBottom: "1.5rem" }}
							>
								Reset Password
							</h2>
							<Form.Group
								className="form-group-custom"
								controlId="formBasicPassword"
							>
								<div className="label-container">
									<Form.Label>New Password</Form.Label>
									<div className="errors">
										{errors.password}
									</div>
								</div>
								<Form.Control
									className="auth-text-input"
									type="password"
									name="password"
									value={values.password}
									onChange={handleChange}
									isInvalid={!!errors.password}
									placeholder="Password"
								/>
							</Form.Group>
							<Form.Group
								className="form-group-custom"
								controlId="formBasicPassword"
							>
								<div className="label-container">
									<Form.Label>Confirm </Form.Label>
									<div className="errors">
										{errors.confirm}
									</div>
								</div>
								<Form.Control
									className="auth-text-input"
									type="password"
									name="confirm"
									value={values.confirm}
									onChange={handleChange}
									isInvalid={!!errors.confirm}
									placeholder="Confirm"
								/>
							</Form.Group>
							<div className="form-container-centered form-submit-container">
								<Button
									variant="primary"
									className="relative-btn"
									type="submit"
								>
									<span className="text">Submit</span>
									<span className="arrow">
										<img src={arrow_black} />
									</span>
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default ResetForm;
