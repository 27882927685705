import PropTypes from "prop-types";
import React from "react";

// import classes from "styles/YouTubeVideo.module.css";

class YouTubeVideo extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  componentDidMount = () => {
    // On mount, check to see if the API script is already loaded

    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // If script is already there, load the video directly
      this.loadVideo();
    }
  };

  loadVideo = () => {
    const { id } = this.props;

    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player-${id}`, {
      videoId: id,
      width: 1280, // Player width (in px)
      height: 720, // Player height (in px)
      playerVars: {
        playlist: "{$loadYouTubeVideoID}",
        autoplay: 1, // Auto-play the video on load
        autohide: 1,
        disablekb: 1,
        controls: 0, // Hide pause/play buttons in player
        showinfo: 0, // Hide the video title
        modestbranding: 1, // Hide the Youtube Logo
        loop: 1, // Run the video in a loop
        fs: 0, // Hide the full screen button
        autohide: 0, // Hide video controls when playing
        rel: 0,
        enablejsapi: 1,
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: function(e) {
          if (e && e.data === 1) {
            var videoHolder = document.getElementById("home-banner-box");

            if (videoHolder && videoHolder.id) {
              videoHolder.classList.remove("loading");
            }
          } else if (e && e.data === 0) {
            e.target.playVideo();
          }
        },
      },
    });
  };

  onPlayerReady = (event) => {
    event.target.mute();
    event.target.setPlaybackQuality("hd1080");
    event.target.playVideo();
  };

  render = () => {
    const { id } = this.props;
    return (
      <div className="yt-player">
        <div id={`youtube-player-${id}`} />
      </div>
    );
  };
}

export default YouTubeVideo;
