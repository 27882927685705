import React from "react";
import "./PolicyPages.scss";
import arrow_blk from "../../images/arrow-blk.png";
import logo from "../../images/logo_m.png";

const PolicyMenu = () => {
	return (
		<div className="policy-menu">
			<div className="back" onClick={() => window.history.back()}>
				<img src={arrow_blk} alt="back arrow" />
				<span>BACK</span>
			</div>
			<div className="logo-container">
				<img src={logo} alt="logo" />
			</div>
			<div />
		</div>
	);
};

export default PolicyMenu;
