import axios from "axios";

// CHANGE FOR HEROKU:

const serverUrl = "https://submission-dashboard.herokuapp.com";
// const serverUrl = "http://localhost:5000";

export const getSubsById = (id) => {
	return axios
		.get(`${serverUrl}/api/submissions/${id}`)
		.then((res) => res.data);
};

export const getReferrer = (id) => {
	return axios.get(`/api/users/find/${id}`).then((res) => res.data);
};

export const submitVideo = (
	values,
	activeUser,
	fileName,
	referrer,
	partner_status,
	channel_id,
	channel_title,
	social
) => {
	const {
		firstName,
		lastName,
		cloudinary_id,
		email,
		phone,
		country,
		altEmail,
		title,
		description,
	} = values;
	return axios
		.post(`${serverUrl}/api/submissions/new`, {
			email,
			user_id: activeUser.user_id,
			cloudinary_id,
			file_name: fileName,
			title,
			name: `${firstName} ${lastName}`,
			phone,
			country,
			altEmail,
			description,
			referrer,
			partner_status,
			channel_id,
			channel_title,
			social,
		})
		.then((res) => res.data);
};

export const updateUploads = (email) => {
	return axios
		.post(`/api/users/updateCount`, { email })
		.then((res) => res.data);
};
