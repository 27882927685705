import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "./Dashboard.scss";
import UploadForm from "./UploadForm";
import MyVideos from "./MyVideos";
import MyEarnings from "./MyEarnings";
import MenuDesktop from "./MenuDesktop";
import MainDashboard from "./MainDashboard";
import Account from "./Account";
import YoutubePartner from "./YoutubePartner";
import MenuMobile from "./MenuMobile";
import { BrowserView, MobileView } from "react-device-detect";

const Dashboard = ({ activeUser }) => {
	const [activeSection, setActiveSection] = useState("dashboard");
	useEffect(() => {
		const flow = window.localStorage.getItem("flow");
		if (flow === "partner") {
			setActiveSection("youtube_partner");
			window.localStorage.removeItem("flow");
		}
	}, []);
	return (
		<div className="dashboard">
			<BrowserView>
				<MenuDesktop
					activeSection={activeSection}
					setActiveSection={setActiveSection}
				/>
				<div className="section-right">
					{activeSection === "dashboard" ? (
						<MainDashboard
							alert={alert}
							activeUser={activeUser}
							setActiveSection={setActiveSection}
						/>
					) : null}
					{activeSection === "upload" ? (
						<UploadForm
							alert={alert}
							activeUser={activeUser}
							setActiveSection={setActiveSection}
						/>
					) : null}
					{activeSection === "videos" ? (
						<MyVideos activeUser={activeUser} />
					) : null}
					{activeSection === "earnings" ? (
						<MyEarnings setActiveSection={setActiveSection} />
					) : null}
					{activeSection === "account" ? (
						<Account activeUser={activeUser} />
					) : null}

					{activeSection === "youtube_partner" ? (
						<YoutubePartner activeUser={activeUser} />
					) : null}
				</div>
			</BrowserView>
			<MobileView>
				<MenuMobile
					activeSection={activeSection}
					setActiveSection={setActiveSection}
				>
					<div className="section-right">
						{activeSection === "dashboard" ? (
							<MainDashboard
								alert={alert}
								activeUser={activeUser}
								setActiveSection={setActiveSection}
							/>
						) : null}
						{activeSection === "upload" ? (
							<UploadForm
								alert={alert}
								activeUser={activeUser}
								setActiveSection={setActiveSection}
							/>
						) : null}
						{activeSection === "videos" ? (
							<MyVideos activeUser={activeUser} />
						) : null}
						{activeSection === "earnings" ? <MyEarnings /> : null}
						{activeSection === "account" ? (
							<Account activeUser={activeUser} />
						) : null}
						{activeSection === "youtube_partner" ? (
							<YoutubePartner activeUser={activeUser} />
						) : null}
					</div>
				</MenuMobile>
			</MobileView>
		</div>
	);
};

export default Dashboard;
