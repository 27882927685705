import React from "react";

const PrivacyPolicy = () => {
	return (
		<div className="policy-container">
			<h1>YOON PRIVACY POLICY</h1>
			<p>
				When you use Yoon’s Services, you will share information with
				us. This Privacy Policy explains the information we collect, how
				we use it, whom we share it with, and the choices we give you to
				control, access, and update your information.
			</p>
			<p>
				This Privacy Policy covers all of Yoon’s services including
				website, email notifications, comments, Yoon-related text
				messages, applications, buttons, widgets, ads, commerce services
				and other covered services, and any information, text links,
				graphics, photos, videos, or other materials or arrangements of
				materials (“Services”) uploaded, downloaded or appearing on the
				Services, including Yoon’s platform and other places accessed
				with or through Yoon (collectively referred to as “Content”).
			</p>
			<h2>INFORMATION WE COLLECT</h2>
			<p>There are three basic categories of information we collect:</p>
			<ul>
				<li>Information you give to us</li>
				<li>Information we get when you use our services</li>
				<li>Information we get from third parties</li>
			</ul>
			<h3>Information You Give to Us</h3>
			<p>
				When you interact with our Services, we collect the information
				that you choose to share with us. For example, certain of our
				Services require you to set up a basic Yoon account, so we need
				to collect a few important details about you, such as: a unique
				username you’d like to go by, a password, an email address, a
				phone number, the streaming service you use to authenticate your
				username and information, and your date of birth. To make it
				easier for others to find you, we may also ask you to provide us
				with some additional information that will be publicly visible
				on our services, such as profile pictures, a name, or other
				useful identifying information. Other services, such as commerce
				products, may also require you to provide us with a debit or
				credit card number and its associated account information.
			</p>
			<p>
				Of course, you’ll also provide us whatever information you send
				through the Services, such as posts, messages or comments made
				on, or through, Yoon to your friends or other users of Yoon.
				Keep in mind that the users you send posts, messages or comments
				made on, or through, Yoon, or any other content may be able to
				save that content or copy it outside of Yoon. Don’t send
				messages or share content that you would not want someone to
				save or share outside of Yoon.
			</p>
			<p>
				We will also collect any information you volunteer when
				contacting customer support or communicating with Yoon.
			</p>
			<h3>Information We Get When You Use Our Services</h3>
			<p>
				When you use the Services, we collect information about which
				Services you’ve used and how you’ve used them. The following are
				types of information we collect when you use our Services:{" "}
			</p>
			<ul>
				<li>
					Usage information. We collect information about your
					activity through the Services. This information may include,
					for example, information about:
				</li>
				<li>
					How you interact with the Services, such as: the videos you
					watch and the comments you make
				</li>
				<li>
					How you communicate with other Yoon users, such as their
					names, the time and date of your communications, the number
					of messages you exchange with other users, which users you
					message most, and interactions with messages (i.e., when you
					open a message, etc.).
				</li>
				<li>
					Content Information. We collect content you create on our
					services, such as, comments, in-app messages and other
					items, and information about the content you create or
					provide, such as if the recipient has viewed the content and
					the metadata that is provided with the content.
				</li>
				<li>
					Device Information. We collect information from and about
					the devices you use. For example, we collect:
				</li>
				<li>
					information about your hardware and software, such as the
					hardware model, operating system version, device memory,
					advertising identifiers, unique application identifiers,
					apps installed, unique device identifiers, browser type,
					language, battery level and time zone;
				</li>
				<li>
					information from device sensors, such as accelerometers,
					gyroscopes, compasses, microphones, and whether you have
					headphones connected; and
				</li>
				<li>
					information about your wireless and mobile network
					connections, such as mobile phone number, service provider,
					and signal strength.
				</li>
				<li>
					Device Phonebook. Yoon may, with your consent, collect
					information from your device’s phonebook.
				</li>
				<li>
					Location Information. When you use our Services, we may
					collect location information. Also, with your consent, we
					may collect information about your location using methods
					that include GPS, wireless networks, cell towers, Wi-Fi
					access points, and other sensors, such as gyroscopes,
					accelerometers, and compasses.
				</li>
				<li>
					Information Collected by Cookies and Other Technologies. We
					may also use cookies and other technologies, such as web
					beacons, web storage, and unique advertising identifiers, to
					collect information about your activity, browser and device.
					We may also use these technologies to collect information
					when you interact with services we offer through Yoon or our
					partners, such as advertising and commerce features. Most
					web browsers are set to accept cookies by default. If you
					prefer, you can usually remove or reject browser cookies
					through the settings on your browser or device. Keep in
					mind, though, that removing or rejecting cookies could
					affect the availability or functionality of our services.
				</li>
				<li>
					Log Information. We also collect log information when you
					use our website, including, among other things:
				</li>
				<li>Details about how you’ve used our Services;</li>
				<li>
					Device information, such as your web browser type or
					language;
				</li>
				<li>Access times;</li>
				<li>Pages viewed;</li>
				<li>IP address;</li>
				<li>
					Identifiers associated with cookies or other technologies
					that may uniquely identify your device or browser; and/or
				</li>
				<li>
					Pages you visited before or after navigating to our website.
				</li>
			</ul>
			<h3>Information We Collect From Third Parties</h3>
			<p>
				We may collect information that other users provide about you
				when they use our services. For example, if another user allows
				us to collect information from their device phonebook—and you’re
				one of that user’s contacts—we may combine the information we
				collect from that user’s phonebook with other information we
				have collected about you. We may also obtain information from
				our affiliates, or any other third-party sources, and combine
				that with the information we collect through our services.{" "}
			</p>

			<h2>HOW WE USE INFORMATION</h2>
			<p>
				We use the information we collect to better provide, and
				improve, our Services. To do that we:
			</p>
			<ul>
				<li>
					develop, operate, improve, deliver, maintain, and protect
					our products and services;
				</li>
				<li>
					send you communications, including by email - for example,
					we may use email to respond to support inquiries or to share
					information about our products, services, and promotional
					offers that we think may interest you;
				</li>
				<li>monitor and analyze trends and usage;</li>
				<li>
					personalize the services by, among other things, suggesting
					friends, networks, or profile information, or customizing
					the content we show you, including ads;
				</li>
				<li>
					contextualize your experience by, among other things,
					tagging your content using your precise location data (if,
					of course, you’ve consented to us collecting that data) and
					applying other labels based on the content;
				</li>
				<li>
					provide and improve ad targeting and measurement, including
					through the use of your precise location data (again, if
					you’ve consented to us collecting that data), both on and
					off our services;
				</li>
				<li>
					enhance the safety and security of our products and
					services;
				</li>
				<li>
					verify your identity and prevent fraud or other unauthorized
					or illegal activity;
				</li>
				<li>
					use of information we’ve collected from cookies and other
					technology to enhance the services and your experience with
					them; and/or
				</li>
				<li>
					enforce our Terms of Use, Contributor Agreement and other
					policies.
				</li>
			</ul>
			<p>
				We may also store some information locally on your device. For
				example, we may store information as local cache so that you can
				open the app and view content faster.
			</p>
			<h3>HOW WE SHARE INFORMATION</h3>
			<p>We may share information about you in the following ways:</p>
			<ul>
				<li>
					With other Yoon users. We may share the following
					information with other Yoon users:
				</li>
				<li>
					information about you, such as your username, name, and Yoon
					profile, pictures, or other icons;
				</li>
				<li>
					information about how you have interacted with the services,
					such as the names of Yoon users you are friends with, and
					other information that will help Yoon users understand your
					connections with others using the services. For example,
					because it may not be clear whether a new friend request
					comes from someone you actually know, we may share whether
					you and the requestor have Yoon friends in common;
				</li>
				<li>
					any additional information you have consented for us to
					share;
				</li>
				<li>
					content you post or send will be shared with other Yoon
					users; how widely your content is shared depends on your
					personal settings and the type of service you are using.
					Generally, Yoon profiles are publicly available to other
					Yoon users and accessible through user profiles.
				</li>
				<li>
					With all Yoon users, our business partners, and the general
					public. We may share the following information with all Yoon
					users, as well as with our business partners and the general
					public:
				</li>
				<li>
					Public information like your name, username, and profile
					pictures;
				</li>
				<li>
					Any content that you submit to crowd-sourced services using
					Yoon. If a post or other content, including other
					crowd-sourced services, is streamed on the web or broadcast
					in some other media, it may be viewed by the public at large
					or rebroadcast by our business partners.
				</li>
				<li>
					With our affiliates. We may share information with entities
					and business with which we are affiliated.
				</li>
				<li>
					With third-parties. We may share your information with the
					following third parties:
				</li>
				<li>
					With service providers, sellers, and partners. We may share
					information about you with service providers who perform
					services on our behalf, sellers that provide goods through
					our services, and business partners that provide services
					and functionality.
				</li>
				<li>
					With third parties for legal reasons. We may share
					information about you if we reasonably believe that
					disclosing the information is to: (i) comply with any valid
					legal process, governmental request, or applicable law, rule
					or regulation, (ii) investigate, remedy, or enforce
					violations of our Terms of Use, (iii) protect the rights,
					property, and safety of Yoon, our users, or others, and/or
					(iv) detect and resolve any fraud or security concerns.
				</li>
				<li>
					With third parties as part of a merger or acquisition. If
					Yoon is involved in a merger, asset sale, financing,
					liquidation or bankruptcy, or acquisition of all or some
					portion of our business to another company, we may share
					your information with that company before and after the
					transaction closes.
				</li>
			</ul>
			<p>
				We may also share information with third parties—such as
				advertisers—aggregated, non-personally identifiable, or
				de-identified information.
			</p>

			<h3>THIRD-PARTY CONTENT AND INTEGRATIONS</h3>
			<p>
				The services may also contain third-party links and search
				results, include third-party integrations, or offer a co-branded
				or third-party-branded service. Through these links, third-party
				integrations, and co-branded or third-party-branded services,
				you may be providing information (including personal
				information) directly to the third party, us, or both. You
				acknowledge and agree that we are not responsible for how those
				third parties collect or use your information. As always, we
				encourage you to review the privacy policies of every
				third-party service that you visit or use, including those third
				parties you interact with through our services.
			</p>

			<h3>ANALYTICS AND ADVERTISING SERVICES</h3>
			<h4>Provided by Others</h4>
			<p>
				We may let other companies use cookies, web beacons, and similar
				technologies on our services. These companies may collect
				information about how you use our services over time and combine
				it with similar information from other services and companies.
				This information may be used to, among other things, analyze and
				track data, determine the popularity of certain content, and
				better understand your online activity.
			</p>

			<p>
				Additionally, some companies, including our affiliates, may use
				information collected on our services to measure the performance
				of ads and deliver more relevant ads on behalf of us or other
				companies, including on third-party websites and apps.
			</p>

			<h4>Provided by US</h4>
			<p>
				We may collect information about your activity on third-party
				services that use cookies and other technologies provided by us.
				We use this information to improve our advertising services,
				including measuring the performance of ads and showing you more
				relevant ads.
			</p>

			<h3>CONTROL OVER YOUR INFORMATION</h3>
			<p>
				On Yoon, you control your information. We provide the following
				tools for you to do so.
			</p>
			<ul>
				<li>
					Access and updates. We want you to be able to access and
					update most of the personal information that we have about
					our users. You can access and update most of your basic
					account information right in the app by visiting the app’s
					Settings page. If you need to access, update, or delete
					other personal information that we may have please contact
					support@yoondigital.com. Because your privacy is important,
					we may ask you to verify your identity or provide additional
					information before we let you access or update your personal
					information. We will try to allow you to update and access
					your information for free, but if your request would require
					a disproportionate effort on our part, we may charge a fee.
					In such a case, we would disclose the fee before complying
					with your request. We may, however, reject requests to
					access or update information for a number of reasons,
					including, for example, that the request risks the privacy
					of other users, requires technical efforts that are
					disproportionate to the request, is repetitive, or is
					unlawful.
				</li>
				<li>
					Revoking permissions. If you change your mind about our
					ongoing ability to collect information from certain sources
					that you have already consented to, such as your phonebook
					or location services, you can simply revoke your consent by
					changing the settings on your device if your device offers
					those options. Of course, if you do that, certain services
					may lose full functionality.
				</li>
				<li>
					Account deletion. We want you to remain a Yoon user for
					life, however, if for some reason you want to delete your
					account, you may do so by sending us an email at
					support@yoondigital.com. If you ask to delete your account,
					you will have up to 30 days to restore your account before
					we delete your information from our servers. During that
					period, your account will not be visible to other Yoon
					users.
				</li>
				<li>
					Advertising preferences. We try to show you ads that we
					think will be related to your interests. If you would like
					to modify the information we and our advertising partners
					use to select these ads, go to:
					www.yoondigital.com/adpreferences, to learn about the
					choices available to you and changes you may make.
				</li>
				<li>
					Communicating with other Yoon users. Yoon profiles are
					generally public. As such, all Content you post on Yoon is
					viewable publicly. However, we want you to remain in control
					over whom you communicate with via Yoon. As such, we make
					available tools in our Settings that let you indicate, among
					other things, who you want to view your posts, whom you’d
					like to receive posts from, and whether you’d like to block
					certain Yoon users from contacting you.
				</li>
			</ul>

			<h3>CHILDREN</h3>
			<p>
				We do not knowingly collect personal information from anyone
				under 13 years old. As provided in our Terms of Use, our
				services are not intended for anyone under 13 years old. Yoon
				users must be at least 13 years old to access and use our
				Services.
			</p>

			<h3>CHANGES TO THE PRIVACY POLICY</h3>
			<p>
				We may change this Privacy Policy from time to time. But when we
				do, we’ll let you know one way or another. Sometimes, we’ll let
				you know by revising the date at the top of the Privacy Policy
				that’s available on our website and mobile application. Other
				times, we may provide you with additional notice (such as adding
				a statement to our websites’ homepages or providing you with an
				in-app notification). The most current version of the Privacy
				Policy will always govern our use of your information. By
				continuing to access or use our Services after changes to the
				Privacy Policy become effective, you agree to be bound by the
				revised Privacy Policy.{" "}
			</p>

			<p>
				For questions about our Privacy Policy, please email
				support@yoondigital.com.
			</p>
		</div>
	);
};

export default PrivacyPolicy;
