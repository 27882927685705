import React, { Fragment, useEffect, useState } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {
	handleRegister,
	handleLogIn,
	ipLookUp,
	checkIfBanned,
	oAuthSignIn,
} from "../services/auth-requests";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import "./PartnerRegister.scss";
import yoon from "../images/logo.png";
import hoodville from "../partner_assets/images/hoodville/logo.jpg";
import petsonq from "../partner_assets/images/pets-on-q/logo.png";

const PartnerRegister = () => {
	const alert = useAlert();
	const [referrer, setReferrer] = useState(null);
	useEffect(() => {
		window.scrollTo(0, 0);
		const params = new URLSearchParams(window.location.search);
		setReferrer(params.get("r"));
	}, []);
	const responseFacebook = (response) => {
		if (response.email) {
			handleOAuthRegister(
				response.email,
				response.id,
				response.accessToken,
				"facebook"
			);
		} else {
			console.log(response);
		}
	};
	const responseGoogle = (response) => {
		if (response.profileObj) {
			handleOAuthRegister(
				response.profileObj.email,
				response.googleId,
				response.tokenObj.access_token,
				"google"
			);
		} else {
			console.log(response);
		}
	};
	const handleOAuthRegister = async (email, id, accessToken, method) => {
		const isBanned = await checkIfBanned();
		if (isBanned.data.banned) {
			alert.error("Account or IP has been banned");
			return;
		}
		const oAuthRegisterFn = (city, region, country, addressIP) => {
			return oAuthSignIn(
				email,
				accessToken,
				city,
				region,
				country,
				addressIP,
				referrer,
				method
			).then((result) => {
				console.log(result);
				if (result.message === "User hasn't agreed to terms yet") {
					window.location.href = `/terms-and-conditions?user=${email}&id=${id}&method=${method}`;
				} else if (result.success) {
					window.location.href = "/dashboard";
				} else {
					alert.error(result.message);
				}
			});
		};

		ipLookUp()
			.then((data) => {
				const { city, region, country_name } = data;
				const addressIP = data.ip;
				oAuthRegisterFn(city, region, country_name, addressIP);
			})
			.catch((err) => {
				oAuthRegisterFn();
			});
	};
	return (
		<Fragment>
			<div className="partner-register-container">
				<Card sectioned>
					<div className="logo-cont">
						<img src={yoon} alt="yoon logo" />
					</div>
					<h1>Sign Up</h1>
					{referrer === "hoodville" ? (
						<div className="logo-cont brand-logo-cont hoodville">
							<img src={hoodville} alt="yoon logo" />
						</div>
					) : null}
					{referrer === "petsonq" ? (
						<div className="logo-cont brand-logo-cont pets-on-q">
							<img src={petsonq} alt="yoon logo" />
						</div>
					) : null}
					<Formik
						initialValues={{
							email: "",
							password: "",
							agree: "",
							adult: "",
						}}
						validate={(values) => {
							const { email, password, agree, adult } = values;
							let errors = {};
							if (!email) {
								errors.email = "Required";
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
									email
								)
							) {
								errors.email = "Invalid email address";
							}
							const hasNumbers = /\d/.test(password);
							const hasNonalphas = /\W/.test(password);

							if (
								password.length < 6 ||
								!(hasNumbers || hasNonalphas)
							) {
								errors.password =
									"Password must be at least 6 characters and contain either a number or a special character.";
							}
							if (!password) {
								errors.password = "Required";
							}

							return errors;
						}}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={async (values, { setSubmitting }) => {
							const { email, password, agree, adult } = values;

							if (!validateAgreeAdult(agree, adult)) return;
							const registerFn = (
								city,
								region,
								country,
								addressIP
							) => {
								let partnerReferrer;
								if (!referrer) {
									partnerReferrer = window.localStorage.getItem(
										"referrer"
									);
								} else {
									partnerReferrer = referrer;
								}
								return handleRegister(
									email,
									password,
									city,
									region,
									country,
									addressIP,
									partnerReferrer
								).then((result) => {
									if (result.success) {
										window.location.href = "/new-user";
										setSubmitting(false);
									} else {
										alert.error(result);
										setSubmitting(false);
									}
								});
							};
							try {
								const ipData = await ipLookUp();
								const {
									city,
									country_name,
									region,
									ip,
								} = ipData;
								const isBanned = await checkIfBanned(ip);
								if (isBanned.data.banned) {
									alert.error(
										"Account or IP has been banned"
									);
									setSubmitting(false);
									return;
								}
								await registerFn(
									city,
									region,
									country_name,
									ip
								);
							} catch (err) {
								registerFn();
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group
									className="form-group-custom"
									controlId="formBasicEmail"
								>
									<div className="label-container">
										<Form.Label>Email address</Form.Label>
									</div>
									<Form.Control
										className="auth-text-input"
										type="text"
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={!!errors.email}
										placeholder="Enter email"
									/>
									<div className="errors">{errors.email}</div>
								</Form.Group>
								<Form.Group
									className="form-group-custom"
									controlId="formBasicPassword"
								>
									<div className="label-container">
										<Form.Label>Password </Form.Label>
									</div>
									<Form.Control
										className="auth-text-input"
										type="password"
										name="password"
										value={values.password}
										onChange={handleChange}
										isInvalid={!!errors.password}
										placeholder="Password"
									/>
									<div className="errors">
										{errors.password}
									</div>
								</Form.Group>
								<Form.Group
									className="form-group-custom form-check"
									controlId="formBasicAgree"
								>
									<label className="agree check">
										I agree to the terms and conditions
										<input
											name="agree"
											type="checkbox"
											value={values.agree}
											onChange={handleChange}
										/>
										<span className="checkmark"></span>
									</label>
								</Form.Group>
								<Form.Group
									className="form-group-custom form-check"
									controlId="formBasicAdult"
								>
									<label className="adult check">
										I am 18 years old or older
										<input
											name="adult"
											type="checkbox"
											value={values.adult}
											onChange={handleChange}
										/>
										<span className="checkmark"></span>
									</label>
								</Form.Group>
								<div className="form-container-centered">
									<Button variant="primary" type="submit">
										Sign Up
									</Button>
								</div>
							</Form>
						)}
					</Formik>
					<div className="">
						<h2 className="tc">OR...</h2>
						<div className="form-container-centered">
							<FacebookLogin
								appId="1197671610420193"
								fields="name,email"
								callback={responseFacebook}
								isMobile={false}
								disableMobileRedirect={true}
								render={(renderProps) => (
									<button
										className="oauth-btn fb-login"
										onClick={renderProps.onClick}
									>
										<span className="text">
											Sign Up with Facebook
										</span>
									</button>
								)}
							/>
						</div>
						<div className="form-container-centered">
							<GoogleLogin
								clientId="677427210058-lp41b6d082s34cdb46qfjf83u234kunq.apps.googleusercontent.com"
								render={(renderProps) => (
									<button
										className="oauth-btn google-login"
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
									>
										<span className="text">
											Sign Up with Google
										</span>
									</button>
								)}
								autoLoad={false}
								onSuccess={responseGoogle}
								onFailure={responseGoogle}
								cookiePolicy={"single_host_origin"}
							/>
						</div>
					</div>
				</Card>
				<Navbar bg="dark" variant="dark" expand="lg">
					<Nav>
						<Nav.Link href="/copyright-policy">
							Contributor Agreement
						</Nav.Link>
						<Nav.Link href="/terms-of-service">
							Terms of Service
						</Nav.Link>
						<Nav.Link href="/privacy-policy">
							Privacy Policy
						</Nav.Link>
					</Nav>
					<Nav>
						<Nav.Link href="/login">
							Already a part of Yoon? Login Now
						</Nav.Link>
					</Nav>
				</Navbar>
			</div>
		</Fragment>
	);
};

const validateAgreeAdult = (agree, adult) => {
	if (!agree && !adult) {
		document
			.querySelector(".agree.check .checkmark")
			.classList.add("is-invalid");
		document
			.querySelector(".adult.check .checkmark")
			.classList.add("is-invalid");
		return false;
	}
	if (!agree) {
		document
			.querySelector(".agree.check .checkmark")
			.classList.add("is-invalid");
		if (!adult) {
			document
				.querySelector(".adult.check .checkmark")
				.classList.add("is-invalid");
			return false;
		} else {
			document
				.querySelector(".adult.check .checkmark")
				.classList.remove("is-invalid");
		}
		return false;
	} else {
		document
			.querySelector(".agree.check .checkmark")
			.classList.remove("is-invalid");
	}

	if (!adult) {
		document
			.querySelector(".adult.check .checkmark")
			.classList.add("is-invalid");
		return false;
	} else {
		document
			.querySelector(".adult.check .checkmark")
			.classList.remove("is-invalid");
	}
	return true;
};
export default PartnerRegister;
