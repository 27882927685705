import Axios from "axios";

export const getProfile = (id) => {
	return Axios.get(`/api/users/profile/${id}`).then((res) => res.data);
};

export const getLoginInfo = (id) => {
	return Axios.get(`/api/users/find/${id}`).then((res) => res.data);
};

export const saveProfile = (values, user_id) => {
	return Axios.post("/api/users/save-profile", { values, user_id }).then(
		(res) => res.data
	);
};

export const createYoutubePartner = (channelObj) => {
	return Axios.post("/api/partners/create", channelObj).then(
		(res) => res.data
	);
};
