import React from "react";

const UploadPageThree = ({ setActiveSection }) => {
	return (
		<div className="page-three-container">
			<h1>You did it!</h1>
			<h6 className="grey">
				You've successfully submitted your video to Yoon. You can check
				the status of your video in the "My Videos" section.
			</h6>
			<h6 style={{ opacity: ".7" }}>
				<strong>
					If you have not already done so, head to the{" "}
					<span
						className="account-link"
						onClick={() => setActiveSection("account")}
					>
						account page
					</span>{" "}
					and add your PayPal email so that you can get paid 🤑
				</strong>
			</h6>
		</div>
	);
};
export default UploadPageThree;
