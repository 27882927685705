import React, { useEffect } from "react";
import "./MainPage.scss";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import starburst from "../images/starburst.png";
import chip from "../images/chip.png";
import arrowRight from "../images/arrow-up-right.png";
import arrow_black from "../images/arrow-blk.png";
import xMark from "../images/x-mark.png";
import header_img from "../images/header_img.png";
import { BrowserView, MobileView } from "react-device-detect";

const MainPage = ({ activeUser }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="main-page-container">
			<div className="bg-image" />
			<div className="left half">
				<div className="main-page-title">
					<BrowserView>
					<h1>
						Take
						<br />
						Viral
						<br />
						<span>to</span>
						<br />
						the
						<br />
						Next
						<br />
						Level
					</h1>
					<span className="up-right-arrow">
						<img src={arrowRight} alt="right arrow" />
					</span>
					<span className="x-icon">
						<img src={xMark} alt="x icon" />
					</span>

					<div className="sign-up-starburst">
						<Link to="/register">
							<img
								className="starburst"
								src={starburst}
								alt="sign up button"
							/>
							<span className="text">Sign up now</span>
							<img
								className="arrow"
								src={arrow_black}
								alt="arrow icon"
							/>
						</Link>
					</div>
					</BrowserView>
					<MobileView>
						<img src={header_img} className="header-img" />
					</MobileView>
				</div>
				<div className="bottom-part">
					<div className="links">
						<Link to="/copyright-policy">
							contributor agreement
						</Link>
						<Link to="/terms-of-service">terms of service</Link>
						<Link to="/privacy-policy">privacy policy</Link>
					</div>
					<img className="chip" src={chip} alt="chip thing" />
				</div>
			</div>
			<div className="right half">
				<div className="page-desc">
					<h2>
						We license your clips & you <br /> get paid!!!
					</h2>
					<p>
						Do you have a hilarious clip? Insane video? Amazing
						film? Sign up today and submit your clip to Yoon! No
						out-of-pocket fees, ever!
					</p>
				</div>
				<Link to="/register">
					<div className="relative-btn signup-button">
						<span className="text"> Sign Up Now +++++</span>
						<img
							className="arrow"
							src={arrow_black}
							alt="arrow icon"
						/>
					</div>
				</Link>
				<div className="bottom-part">
					<div className="learn-more">Learn More</div>
					<div className="copyright-text">COPYRIGHT 2019 ®</div>
				</div>
			</div>
		</div>
	);
};

export default MainPage;
