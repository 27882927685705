import Button from "react-bootstrap/Button";
import React, { useState, useMemo, useEffect, Fragment } from "react";
import { getSubsById } from "../services/submission-requests";
import { getLoginInfo } from "../services/user-requests";
import circle_close from "../images/circle_close.png";
import abc_news from "../images/abc_news.png";

import "./MainDashboard.scss";

const MainDashboard = ({ activeUser, setActiveSection }) => {
	const [hasReceivedVideoData, setHasReceivedVideoData] = useState(false);
	const [hasReceivedPartnerData, setHasReceivedPartnerData] = useState(false);
	const [showVideoTooltip, setShowVideoTooltip] = useState(false);
	const [showPartnerTooltip, setShowPartnerTooltip] = useState(false);
	const [videoRows, setVideoRows] = useState([]);
	useEffect(() => {
		getSubsById(activeUser.user_id).then((rows) => {
			setHasReceivedVideoData(true);
			setShowVideoTooltip(true);
			setVideoRows(rows);
		});
		getLoginInfo(activeUser.user_id).then((data) => {
			setHasReceivedPartnerData(true);
			console.log(data);
			if (data.length && data[0].channel_id && data[0].channel_title) {
			} else {
				setShowPartnerTooltip(true);
			}
		});
	}, []);
	const hasReceivedData = useMemo(
		() => hasReceivedPartnerData && hasReceivedVideoData,
		[hasReceivedPartnerData, hasReceivedVideoData]
	);
	return (
		hasReceivedData && (
			<div className="dashboard-page">
				{showVideoTooltip && (
					<div
						className="dashboard-tooltip"
						onClick={() => setActiveSection("upload")}
					>
						Upload your first clip to the Yoon platform and start
						generating revenue today!
						<div
							className="img-container"
							onClick={(e) => {
								e.stopPropagation();
								setShowVideoTooltip(false);
							}}
						>
							<img src={circle_close} />
						</div>
					</div>
				)}
				{showPartnerTooltip && (
					<div
						className="dashboard-tooltip"
						onClick={() => setActiveSection("youtube_partner")}
					>
						Partner your eligible channel to automatically get your
						latest content monetized and eligible for syncs on major
						networks!
						<div
							className="img-container"
							onClick={(e) => {
								e.stopPropagation();
								setShowPartnerTooltip(false);
							}}
						>
							<img src={circle_close} />
						</div>
					</div>
				)}
				<h3 className="dashboard-header">Welcome to Yoon!</h3>
				<div className="videos-cont">
					<p className="header">Videos</p>
					{videoRows.map((row, i) => (
						<div key={i}>{row.title}</div>
					))}
				</div>
				<div className="embed-cont flex">
					<div className="video-cont">
						<iframe
							width="100%"
							height="auto"
							src="https://www.youtube.com/embed/X6Rl1EWz8hg"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						/>
					</div>
					<div className="description">
						<p>
							Do you have a funny, cute, or entertaining video? Go
							Viral and Get Paid wth Yoon Digital, today!
						</p>
						<p>
							Yoon specializes in promoting and distributing video
							footage to leading news and entertainment outlets
							like MTV, The Discovery Channel, NBC, BBC, and VH1.
							We do this to make money for content creators just
							like you.
						</p>
						<p>
							If your content is selected, we will pitch your
							content to our media partners, negotiate deals on
							your behalf, and protect your copyright. We do all
							of this on a profit share basis so it doesn't cost
							you anything.
						</p>
						<Button variant="primary">Upload a video</Button>
					</div>
				</div>
				<p className="help-text youtube-text">
					Do you have a YouTube channel? Yoon also offers YouTube
					channel partnerships. If your channel is approved, you wll
					be invited to join our YouTube network which is operated by
					an experienced staff of YouTube channel optimization
					experts.
				</p>
				<h3 className="center">As Seen In</h3>
				<p className="help-text center">
					We help our content creators get paid by facilitating syncs
					on large television news networks and cable TV channels.
				</p>
				<div className="flex justify-center">
					<img className="abc-image" src={abc_news} alt="abc news" />
				</div>
			</div>
		)
	);
};
export default MainDashboard;
