import React from "react";

const Logo = ({ blok }) => {
	return (
		<div className="logo-header">
			<img src={blok.image.filename} alt={blok.image.alt} />
		</div>
	);
};

export default Logo;
