import React from "react";
import "./MyEarnings.scss";

const MyEarnings = ({ setActiveSection }) => {
	return (
		<div className="main-container">
			<table>
				<thead>
					<tr>
						<th>Concept</th>
						<th>Amount</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
			<div className="announcement">
				<h6>
					Your earnings will be paid out one (1) month after the
					quarter with a one (1) quarter delay. Below is a table of
					payments for your reference:
					<br />
					<br />
					Earnings January 1st – March 31st to be paid on July 31st
					<br />
					Earnings April 1st – June 30th to be paid on October 31st
					<br />
					Earnings July 1st – September 30th to be paid on January
					31st
					<br />
					Earnings October 1st – December 31st to be paid on April
					30th
					<br />
					<br />
					<strong>
						You must have a valid PayPal email to receive your
						earnings. Head to the{" "}
						<span
							class="account-link"
							onClick={() => setActiveSection("account")}
						>
							account page
						</span>{" "}
						to add your PayPal email and other info!
					</strong>
				</h6>
			</div>
		</div>
	);
};

export default MyEarnings;
