import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./PartnerPage.scss";
import { DynamicComponent, Storyblok } from "../common";
import { Helmet } from "react-helmet";

const PartnerPage = ({ partner, activeUser }) => {
	const [content, setContent] = useState(null);
	const getStoryblokData = async () => {
		console.log("window.StoryblokClient", window.StoryblokClient);
		if (window.StoryblokClient) {
			let Storyblok = new window.StoryblokClient({
				accessToken: "MrhjwmftQvz8EQnieRzihwtt",
				cache: {
					clear: "auto",
					type: "memory",
				},
			});
			console.log("client loaded");
			let { data } = await Storyblok.get(
				`cdn/stories/partners/${partner}`,
				{
					version: "draft", // or 'published'
				}
			);
			console.log(data);
			setContent(data.story.content);
		} else {
			setTimeout(getStoryblokData, 1000);
		}
	};
	useEffect(() => {
		getStoryblokData();
	}, []);
	return (
		<Fragment>
			<Helmet>
				{content ? <title>{content.title} | Yoon</title> : null}
				{content ? (
					<link rel="shortcut icon" href={content.favicon.filename} />
				) : null}
				{content ? (
					<link
						rel="apple-touch-icon"
						href={content.favicon.filename}
					/>
				) : null}
				<script src="https://cdn.jsdelivr.net/npm/storyblok-js-client@4.0.5/dist/index.standalone.js"></script>
			</Helmet>
			<div
				style={{
					backgroundColor: content?.backgroundVideo
						? "transparent"
						: content?.backgroundColor,
					minHeight: "100vh",
				}}
			>
				{content?.backgroundVideo ? (
					<video autoPlay muted loop id="partnerBackgroundVideo">
						<source
							src={content?.backgroundVideo.filename}
							type="video/mp4"
						/>
					</video>
				) : null}
				<div className="partner-page">
					{content?.blocks
						? content.blocks.map((blok) => (
								<DynamicComponent
									key={blok._uid}
									blok={blok}
									handle={content.handle}
								/>
						  ))
						: null}
				</div>
			</div>
		</Fragment>
	);
};

export default PartnerPage;
