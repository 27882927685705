import React, { useState, useEffect, Fragment } from "react";
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Login from "./components/Login";
import Register from "./components/Register";
import PartnerRegister from "./components/PartnerRegister";
import TermsAndConditions from "./components/TermsAndConditions";
import MainPage from "./components/MainPage";
import Dashboard from "./components/Dashboard";
import AdminLogin from "./components/AdminLogin";
import HoodvillePartnerPage from "./components/HoodvillePartnerPage";
import PartnerPage from "./components/PartnerPages/PartnerPage/PartnerPage";
import { LeftBar, RightBar, MobileBar } from "./components/Bars";
import NewUserLandingPage from "./components/NewUserLandingPage";
import { authCheck } from "./services/auth-requests";
import ResetForm from "./components/ResetForm";
import CopyrightPolicy from "./components/PolicyPages/CopyrightPolicy";
import TermsOfService from "./components/PolicyPages/TermsOfService";
import PrivacyPolicy from "./components/PolicyPages/PrivacyPolicy";
import PolicyMenu from "./components/PolicyPages/PolicyMenu";
import logo from "./images/favicon.png";
import { BrowserView, TabletView, MobileOnlyView } from "react-device-detect";
function App(props) {
  const [activeUser, setActiveUser] = useState({});

  useEffect(() => {
    authCheck().then((user) => {
      setActiveUser(user);
    });
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("r")) {
      window.localStorage.setItem("referrer", params.get("r"));
    }
    if (params.get("f") === "partner") {
      window.localStorage.setItem("flow", params.get("f"));
    }
  }, []);

  useEffect(() => {
    console.log("google analytics");
    var s = document.createElement("script"),
      e = !document.body ? document.querySelector("head") : document.body;
    s.src = "https://www.googletagmanager.com/gtag/js?id=G-JK3ZR3FNX5";
    s.async = true;
    s.id = "ga-script";
    s.onload = () => {
      console.log("ga loaded");
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-JK3ZR3FNX5");
    };
    e.appendChild(s);
  }, []);
  useEffect(() => {
    window.smartlook ||
      (function (d) {
        console.log("smartlook");
        var o = (window.smartlook = function () {
            o.api.push(arguments);
          }),
          h = d.getElementsByTagName("head")[0];
        var c = d.createElement("script");
        o.api = new Array();
        c.async = true;
        c.type = "text/javascript";
        c.charset = "utf-8";
        c.src = "https://rec.smartlook.com/recorder.js";
        c.onload = () => {
          console.log("smartlook loaded");
          window.smartlook("init", "d5ceac82fd96bce3248f1cbefa8767a94fc347f4");
        };
        h.appendChild(c);
      })(document);
  }, []);

  if (activeUser.message) {
    return (
      <Fragment>
        <Helmet>
          {!window.location.pathname.includes("partners") ? (
            <link rel="shortcut icon" href={logo} />
          ) : null}
          {!window.location.pathname.includes("partners") ? (
            <link rel="apple-touch-icon" href={logo} />
          ) : null}
          <meta
            name="description"
            content="We license your clips and you get paid!!! Do you have a hilarious clip? Insane video? Amazing film? Sign up today and submit your clip to Yoon!  No out-of-pocket fees, ever!"
          />
        </Helmet>
        <Router>
          <div id="page-container">
            <div id="content-wrap">
              <Route
                path="/login"
                render={() =>
                  activeUser.message === "logged in" ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Fragment>
                      <BrowserView>
                        <LeftBar version="auth" />
                        <RightBar version="auth" />
                      </BrowserView>
                      <TabletView>
                        <LeftBar version="auth" />
                        <RightBar version="auth" />
                      </TabletView>
                      <MobileOnlyView>
                        <MobileBar />
                      </MobileOnlyView>
                      <Login />
                    </Fragment>
                  )
                }
              />
              <Route
                exact
                path="/register"
                render={() =>
                  activeUser.message === "logged in" ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Fragment>
                      <BrowserView>
                        <LeftBar version="auth" />
                        <RightBar version="auth" />
                      </BrowserView>
                      <TabletView>
                        <LeftBar version="auth" />
                        <RightBar version="auth" />
                      </TabletView>
                      <MobileOnlyView>
                        <MobileBar />
                      </MobileOnlyView>
                      <Register />
                    </Fragment>
                  )
                }
              />
              <Route
                path="/register/partner"
                render={() =>
                  activeUser.message === "logged in" ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <PartnerRegister />
                  )
                }
              />
              <Route
                path="/terms-and-conditions"
                render={(matchProps) => <TermsAndConditions {...matchProps} />}
              />
              <Route
                exact
                path="/partners/hoodville"
                render={(props) => {
                  return (
                    <HoodvillePartnerPage
                      partner="hoodville"
                      activeUser={activeUser}
                    />
                  );
                }}
              />
              <Route
                path="/partners/:partner"
                render={(props) => {
                  return (
                    <PartnerPage
                      partner={props.match.params.partner}
                      activeUser={activeUser}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/"
                render={() =>
                  activeUser.message === "logged in" ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Fragment>
                      <BrowserView>
                        <LeftBar version="main" />
                        <RightBar version="main" />
                      </BrowserView>
                      <TabletView>
                        <LeftBar version="main" />
                        <RightBar version="main" />
                      </TabletView>
                      <MobileOnlyView>
                        <MobileBar />
                      </MobileOnlyView>
                      <MainPage activeUser={activeUser} />
                    </Fragment>
                  )
                }
              />
              <Route
                exact
                path="/dashboard"
                render={() =>
                  activeUser.message === "logged in" ? (
                    <Dashboard activeUser={activeUser} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/new-user"
                render={() =>
                  activeUser.message === "logged in" ? (
                    <NewUserLandingPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route path="/reset/:id" component={ResetForm} />
              <Route path="/admin-login" component={AdminLogin} />
              <Route
                path="/terms-of-service"
                render={() => (
                  <Fragment>
                    <PolicyMenu />
                    <TermsOfService />
                  </Fragment>
                )}
              />
              <Route
                path="/copyright-policy"
                render={() => {
                  return (
                    <Fragment>
                      <PolicyMenu />
                      <CopyrightPolicy activeUser={activeUser} />
                    </Fragment>
                  );
                }}
              />
              <Route
                path="/privacy-policy"
                render={() => (
                  <Fragment>
                    <PolicyMenu />
                    <PrivacyPolicy />
                  </Fragment>
                )}
              />
            </div>
          </div>
        </Router>
      </Fragment>
    );
  } else {
    return <div />;
  }
}

export default App;
