import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { getSubsById } from "../services/submission-requests";
import "./MyVideos.scss";
import clock from "../images/clock.png";
import circle_close from "../images/circle_close.png";
import check from "../images/check.png";
import { Video } from "cloudinary-react";

const MyVideos = ({ activeUser }) => {
	const [videoRows, setVideoRows] = useState([]);
	useEffect(() => {
		getSubsById(activeUser.user_id).then(rows => {
			setVideoRows(rows);
			console.log(rows);
		});
	}, []);
	return (
		<div className="main-container">
			<table>
				<thead>
					<tr>
						<th>Thumbnail</th>
						<th>Title</th>
						<th>Submission Status</th>
					</tr>
				</thead>
				<tbody>
					{videoRows.map((row, i) => {
						return (
							<tr key={i}>
								<td>
									<Video
										cloudName="yoon-digital"
										publicId={`${row.cloudinary_id}.jpg`}
										resourceType="video"
										width="140"
									/>
								</td>
								<td>{row.title ? row.title : row.file_name}</td>
								<td>
									<ApprovalStatus status={row.status} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

const ApprovalStatus = ({ status }) => {
	return (
		<div className={`approval-status ${status}`}>
			{status === "approved" ? (
				<div className="img-container">
					<img className="check" src={check} />
				</div>
			) : null}
			{status === "pending" ? (
				<div className="img-container">
					<img src={clock} />
				</div>
			) : null}
			{status === "rejected" ? (
				<div className="img-container">
					<img src={circle_close} />
				</div>
			) : null}
			{status === "approved" ? (
				<div className="txt-container">Monetized</div>
			) : null}
			{status === "pending" ? (
				<div className="txt-container">Pending</div>
			) : null}
			{status === "rejected" ? (
				<div className="txt-container">Denied</div>
			) : null}
		</div>
	);
};

export default MyVideos;
