import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import "./Bars.scss";
import menu_white from "../images/menu-white.png";
import { slide as Menu } from "react-burger-menu";
import logo_m from "../images/logo_m.png";

export const LeftBar = ({ version }) => {
	return (
		<div className={`side-menu left desktop ${version}`}>
			<div className="top">
				<Link to="/">
					<div className="logo-container relative">
						<img src={logo} />
					</div>
				</Link>
				<Link
					className={`menu-text ${
						version === "auth" ? "yellow" : ""
					} log-in`}
					to="/login"
				>
					log in
				</Link>
				<Link
					className={`menu-text ${
						version === "main" ? "yellow" : ""
					} log-in`}
					to="/register"
				>
					sign up now
				</Link>
			</div>
			<div className="bottom">
				<Link
					className="menu-text"
					to="/contact"
					style={{ display: "none" }}
				>
					contact us
				</Link>
			</div>
		</div>
	);
};

export const RightBar = ({ version }) => {
	return (
		<Link to="/register">
			<div className={`side-menu right desktop ${version}`}>
				<span className="menu-text right">
					sign up now + sign up now + sign up now + sign up now + sign
					up now + sign up now + sign up now
				</span>
			</div>
		</Link>
	);
};

export class MobileBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false
		};
	}
	// This keeps your state in sync with the opening/closing of the menu
	// via the default means, e.g. clicking the X, pressing the ESC key etc.
	handleStateChange(state) {
		this.setState({ menuOpen: state.isOpen });
	}

	// This can be used to close the menu, e.g. when a user clicks a menu item
	closeMenu() {
		this.setState({ menuOpen: false });
	}

	// This can be used to toggle the menu, e.g. when using a custom icon
	// Tip: You probably want to hide either/both default icons if using a custom icon
	// See https://github.com/negomi/react-burger-menu#custom-icons
	toggleMenu() {
		this.setState(state => ({ menuOpen: !state.menuOpen }));
	}
	render() {
		return (
			<Fragment>
				<div className="side-menu mobile">
					<Link to="/">
						<div className="logo-container">
							<img src={logo_m} />
						</div>
					</Link>
				</div>
				<Menu
					right
					isOpen={this.state.menuOpen}
					onStateChange={state => this.handleStateChange(state)}
				>
					<div className="mobile-menu">
						<div className="menu-content">
							<div className="top upper">
								<h1>Yoon</h1>
								<Link
									onClick={() => this.closeMenu()}
									className="menu-item menu-text"
									to="/login"
								>
									Login
								</Link>
								<Link
									onClick={() => this.closeMenu()}
									className="menu-item menu-text"
									to="/register"
								>
									Sign Up
								</Link>
								<Link
									onClick={() => this.closeMenu()}
									className="menu-item menu-text"
									to="/contact-us"
									style={{ display: "none" }}
								>
									Contact Us
								</Link>
							</div>
							<div className="bottom">
								<Link
									onClick={() => this.closeMenu()}
									className="menu-item menu-text"
									to="/copyright-policy"
								>
									Copyright policy
								</Link>
								<Link
									onClick={() => this.closeMenu()}
									className="menu-item menu-text"
									to="/terms-of-service"
								>
									Terms of service
								</Link>
								<Link
									onClick={() => this.closeMenu()}
									className="menu-item menu-text"
									to="/privacy-policy"
								>
									Privacy Policy
								</Link>
							</div>
						</div>
					</div>
				</Menu>
			</Fragment>
		);
	}
}
