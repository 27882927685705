import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { submitReset } from "../services/auth-requests";
import "./Auth.scss";
import { useAlert } from "react-alert";
import arrow_black from "../images/arrow-blk.png";
import "./HoodvillePartnerPage.scss";
import YoutubeVideo from "./YoutubeVideo";
import lil_meatball from "../partner_assets/videos/hoodville/lil_meatball_dance.mp4";
import supahot from "../partner_assets/videos/hoodville/supahot.mp4";
import supa_hot from "../partner_assets/videos/hoodville/supahot.gif";
import hoodville from "../partner_assets/images/hoodville/logo.jpg";
import { Helmet } from "react-helmet";

const PartnerPage = ({ activeUser, partner }) => {
	const alert = useAlert();
	const [videoSrc, setVideoSrc] = useState(null);
	const [logoSrc, setLogoSrc] = useState(null);
	useEffect(() => {
		const partnerVidMap = {
			hoodville: [lil_meatball],
		};

		const partnerLogoMap = {
			hoodville,
		};
		const max = partnerVidMap[partner].length;
		const vidNum = Math.floor(Math.random() * max);
		console.log(partnerVidMap[partner][vidNum]);
		setVideoSrc(partnerVidMap[partner][vidNum]);
		setLogoSrc(partnerLogoMap[partner]);
	}, [partner]);
	return (
		<Fragment>
			<Helmet>
				<title>Hoodville | Yoon</title>
				<link rel="shortcut icon" href={hoodville} />
				<link rel="apple-touch-icon" href={hoodville} />
			</Helmet>
			<div className="partner-page">
				<div className="partner-video">
					<img src={supa_hot} alt="supa hot" />
				</div>
				<div className="partner-details">
					<div class="logo-cont">
						<img src={logoSrc} alt="hoodville" />
					</div>
					<div class="signup-btn">
						<Link to="/register/partner?r=hoodville">
							<Button>
								<span>Submit your Video</span>
								<svg
									id="_x31_px"
									enable-background="new 0 0 24 24"
									height="512"
									viewBox="0 0 24 24"
									width="512"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="m13.5 23h-3c-.552 0-1-.449-1-1v-5h-2.5c-.202 0-.385-.122-.462-.309s-.034-.402.108-.545l5-5c.195-.195.512-.195.707 0l5 5c.143.143.186.358.108.545s-.259.309-.461.309h-2.5v5c0 .551-.448 1-1 1zm-5.293-7h1.793c.276 0 .5.224.5.5v5.5h3v-5.5c0-.276.224-.5.5-.5h1.793l-3.793-3.793z" />
									<path d="m4.501 16.97c-.019 0-.037-.001-.057-.003-2.533-.281-4.444-2.417-4.444-4.967 0-2.642 2.059-4.811 4.656-4.988.692-3.456 3.777-6.012 7.344-6.012 2.801 0 5.381 1.578 6.653 4.044 3.022.317 5.347 2.878 5.347 5.956 0 2.68-1.796 5.05-4.366 5.762-.268.073-.542-.083-.615-.349-.074-.266.082-.542.348-.615 2.139-.592 3.633-2.566 3.633-4.798 0-2.647-2.063-4.835-4.697-4.981-.185-.01-.348-.121-.426-.288-1.058-2.266-3.364-3.731-5.877-3.731-3.215 0-5.977 2.395-6.425 5.57-.035.246-.246.43-.495.43-2.286 0-4.08 1.794-4.08 4 0 2.04 1.528 3.748 3.556 3.973.274.031.472.278.441.552-.028.256-.245.445-.496.445z" />
								</svg>
							</Button>
						</Link>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PartnerPage;
