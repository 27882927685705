import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { CountryDropdown } from "react-country-region-selector";
import arrow from "../images/arrow.png";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const UploadPageTwo = ({
	activeUser,
	fileName,
	setFileName,
	toggleUploadModal,
	submitFile,
	submitPageTwo,
	progress,
	isLoading,
}) => {
	const [phoneCountry, setPhoneCountry] = useState("");
	const [country, setCountry] = useState("");
	return (
		<Formik
			initialValues={{
				firstName: "",
				lastName: "",
				email: activeUser.email,
				phone: "",
				country: "",
				altEmail: "",
				title: fileName,
				description: "",
			}}
			validate={(values) => {
				console.log(values);
				let errors = {};
				if (!country) {
					errors.country = "Required";
				}
				if (!values.firstName) {
					errors.firstName = "Required";
				}
				if (!values.lastName) {
					errors.lastName = "Required";
				}
				if (!values.description) {
					errors.description = "Required";
				}
				if (!phoneCountry) {
					errors.phone = "Required";
				}
				if (!country) {
					errors.country = "Required";
				}
				return errors;
			}}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={async (values, { setSubmitting }) => {
				values.phone = phoneCountry;
				values.country = country;
				await submitPageTwo(values);
				setSubmitting(false);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<Form noValidate onSubmit={handleSubmit}>
					<h3 className="page-two-header">
						Tell us about your clip!
					</h3>
					<div className="form-page-two">
						<Form.Row>
							<Form.Group
								as={Col}
								className="form-input first"
								controlId="formGridFirst"
							>
								<Form.Label>First Name</Form.Label>
								<Form.Control
									className="auth-text-input"
									type="text"
									name="firstName"
									value={values.firstName}
									onChange={handleChange}
									isInvalid={!!errors.firstName}
									placeholder="Type here..."
								/>
							</Form.Group>

							<Form.Group
								as={Col}
								className="form-input second"
								controlId="formGridLast"
							>
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									className="auth-text-input"
									type="text"
									name="lastName"
									value={values.lastName}
									onChange={handleChange}
									isInvalid={!!errors.lastName}
									placeholder="Type here..."
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group
								as={Col}
								className="form-input"
								controlId="formGridEmail"
							>
								<Form.Label>Your Email</Form.Label>
								<Form.Control
									className="auth-text-input"
									type="email"
									name="email"
									value={values.email}
									onChange={handleChange}
									isInvalid={!!errors.email}
									placeholder="Type here..."
									readOnly
								/>
							</Form.Group>

							<Form.Group
								as={Col}
								className="form-input"
								controlId="formGridPhone"
							>
								<Form.Label>Phone Number</Form.Label>
								<div className="phone-container">
									<PhoneInput
										placeholder="Type here..."
										value={phoneCountry}
										onChange={(phoneCountry) => {
											console.log(phoneCountry);
											setPhoneCountry(phoneCountry);
										}}
										defaultCountry="US"
									/>
								</div>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group
								as={Col}
								className="form-input"
								controlId="formGridEmailSecondary"
							>
								<Form.Label>
									Alternate Email (Optional)
								</Form.Label>
								<Form.Control
									className="auth-text-input"
									type="email"
									name="altEmail"
									value={values.altEmail}
									onChange={handleChange}
									isInvalid={!!errors.altEmail}
									placeholder="Type here..."
								/>
							</Form.Group>

							<Form.Group
								as={Col}
								className="form-input"
								controlId="formGridCountry"
							>
								<Form.Label>Country</Form.Label>
								<CountryDropdown
									classes="form-control"
									value={country}
									priorityOptions={["US", "GB"]}
									onChange={(val) => setCountry(val)}
								/>
								<div
									className="form-control is-invalid"
									style={{ display: "none" }}
								></div>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group
								as={Col}
								className="form-input"
								controlId="formBasicEmail"
							>
								<Form.Label>Description</Form.Label>
								<Form.Control
									className="auth-text-input"
									as="textarea"
									rows="5"
									name="description"
									value={values.description}
									onChange={handleChange}
									isInvalid={!!errors.description}
									placeholder="Tell us about your video! Where was it taken? What is happening? When was the video taken?"
								/>
							</Form.Group>
							<div className="file-name-finish">
								<Form.Group
									as={Col}
									className="form-input"
									controlId="formBasicEmail"
								>
									<Form.Label>Title</Form.Label>
									<Form.Control
										className="auth-text-input"
										type="text"
										name="title"
										value={values.title}
										onChange={handleChange}
										isInvalid={!!errors.title}
										placeholder="Type here..."
									/>
								</Form.Group>
								<Button
									variant="primary"
									type="submit"
									className={
										isLoading
											? "submit-btn page-two disabled"
											: !country ||
											  !values.firstName ||
											  !values.lastName ||
											  !values.description ||
											  !phoneCountry
											? "submit-btn page-two disabled"
											: "submit-btn page-two"
									}
									disabled={
										isLoading
											? true
											: !country ||
											  !values.firstName ||
											  !values.lastName ||
											  !values.description ||
											  !phoneCountry
											? true
											: false
									}
								>
									{isLoading ? (
										<Fragment>
											<Loader
												type="TailSpin"
												color="#FFF"
												height={30}
												width={30}
											/>
											<span className="loading">
												Loading, this could take a
												minute...
											</span>
										</Fragment>
									) : (
										<span>Finish</span>
									)}

									<span className="arrow-container">
										<img src={arrow} alt="arrow icon" />
									</span>
								</Button>
							</div>
						</Form.Row>
					</div>
					{progress === null ? null : <ProgressBar now={progress} />}
				</Form>
			)}
		</Formik>
	);
};

export default UploadPageTwo;
