import React from "react";

const TermsOfService = () => {
	return (
		<div className="policy-container">
			<h1>YOON TERMS OF USE</h1>

			<p>
				Welcome to Yoon. These Terms of Use (“Terms”) govern your access
				to, and use of, our services including website, email
				notifications, comments, Yoon-related messages, applications,
				buttons, widgets, ads, commerce services and other covered
				services, and any information, text links, graphics, photos,
				videos, or other materials or arrangements of materials
				(“Services”) uploaded, downloaded or appearing on the Services,
				including Yoon’s platform and other places accessed with or
				through Yoon (collectively referred to as “Content”). By using
				the Services, you agree to be bound by these Terms set forth in
				this agreement (the “Agreement”).
			</p>

			<p>
				By accessing, browsing, or otherwise using the Services, you
				agree to all the terms and conditions in this Agreement. Please
				read them carefully.
			</p>

			<h3>WHO CAN USE THE SERVICES</h3>

			<p>
				No one under thirteen (13) years old is allowed to create an
				account or use the Services. We may offer additional Services
				with additional terms that may require you to be even older to
				use them, so please read all terms carefully.
			</p>

			<p>By using the Services, you state that:</p>
			<ul>
				<li>You can form a binding contract with Yoon</li>
				<li>
					You are not a person who is barred from receiving the
					Services under the laws of the United States or any other
					applicable jurisdiction – meaning that you do not appear on
					the U.S. Treasury Department’s list of Specially Designated
					Nationals or face any other similar prohibition
				</li>
				<li>
					You will comply with these Terms and all applicable local,
					state, national and international, laws, rules and
					regulations.
				</li>
			</ul>
			<h3>OWNERSHIP OF THE WEBSITE, APPLICATION, AND SOFTWARE</h3>

			<p>
				Yoon owns the website, application and software that power
				Yoon’s Services. Yoon also owns all of the Content that it
				creates for its Services whether it be in the form of code,
				text, images, graphics, designs, logos, formats, systems, or
				displays, including without limitation all copyrights, patents,
				trademarks and trade secrets associated with its Services
				(collectively, “Intellectual Property”). Yoon does not own the
				Content available through its Services that is created by its
				users, publishers, or third parties. Nothing in these Terms will
				be interpreted to transfer any ownership interest in Yoon’s
				Intellectual Property or the Intellectual Property of others to
				you or any third party. Your rights to access the Services are
				strictly limited to the rights expressly granted to you below.
			</p>

			<h3>RIGHTS WE GRANT YOU</h3>

			<p>
				Yoon grants you a personal, worldwide, royalty-free,
				non-assignable, nonexclusive, revocable, and non-sublicensable
				license to access and use the Services for non-commercial use.
				This license is for the sole purpose of letting you use and
				enjoy the Services’ benefits in a way that these terms and our
				usage policies allow.
			</p>

			<p>
				Any software that we provide you may automatically download and
				install upgrades, updates, or other new features. You may be
				able to adjust these automatic downloads through your device’s
				settings.
			</p>

			<p>
				You may not copy, modify, distribute, sell, or lease any part of
				our Services, nor may you reverse engineer or attempt to extract
				the source code of that software, unless laws prohibit these
				restrictions or you have written permission to do so.
			</p>

			<p>
				You may also not commercially exploit our Services, or use our
				Services to run advertisements or sponsored content without our
				explicit, prior, written permission in each instance.
			</p>

			<h3>RIGHTS YOU GRANT US</h3>

			<p>
				Certain of our Services let you create, upload, post, send,
				receive, and/or store Content. When you do that, you retain
				whatever ownership rights in that Content you had to begin with.
				But, you grant us a license to use that Content.
			</p>

			<p>
				We consider Content set to be made available to everyone to be
				“Public Content.” For all Content you submit to the Services
				other than “Public Content,” you grant Yoon and our affiliates a
				worldwide, royalty-free, sublicensable, and transferable license
				to host, store, use, display, reproduce, modify, adapt, edit,
				publish, and distribute that content. This license is for the
				limited purpose of operating, developing, providing, promoting,
				and improving the Services and researching and developing new
				services.
			</p>

			<p>
				Because Public Content is inherently public and chronicles
				matters of public interest the license you grant us for this
				content is broader. In addition to granting us the rights
				mentioned in the previous paragraph, you also grant us a
				perpetual license to create derivative works from, promote,
				exhibit, broadcast, syndicate, sublicense, publicly perform, and
				publicly display Public Content in any form and in any and all
				media or distribution methods (now known or later developed). To
				the extent it’s necessary, when you appear in, create, upload,
				post, or send Public Content, you also grant Yoon, our
				affiliates, and our business partners the unrestricted,
				worldwide, perpetual right and license to use your name,
				likeness, and voice, including in connection with commercial or
				sponsored content. This means, among other things, that you will
				not be entitled to any compensation from Yoon, our affiliates,
				or our business partners if your name, likeness, or voice is
				conveyed through the Services, either on the Yoon application or
				on one of our business partner’s platforms. Please note that
				these Terms govern your general use of our Services but if you
				upload a video, those specific services are governed separately
				by our Contributor Agreement. Our Contributor Agreement can be
				found at www.yoondigital.com/contributoragreement.
			</p>

			<p>
				Yoon has the right, but not the obligation, to monitor all of
				your online and account activity associated with our Services.
				While we’re not required to do so, we may access, review,
				screen, and delete your Content at any time and for any reason,
				including to provide and develop the Services or if we think
				your Content violates these Terms. You alone, however, remain
				responsible for the Content you create, upload, post, send, or
				store through the Services.
			</p>

			<p>
				Further, if you submit or volunteer feedback or suggestions, we
				reserve the right to use your ideas or comments in any manner
				without compensating you.
			</p>

			<h3>THE CONTENT OF OTHERS</h3>

			<p>
				Much of the Content on our Services, or accessed by our
				Services, is produced by users, publishers, and other third
				parties. Whether that content is posted publicly or sent
				privately, the content is the sole responsibility of the person
				or organization that submitted it. Although Yoon reserves the
				right to review or remove, or prohibit access to, all Content
				that appears on the Services, we do not necessarily review all
				of it. We cannot—and do not—take responsibility for any Content
				that others provide through the Services.
			</p>

			<p>
				We do not want our Services used in bad or unlawful ways.
				However, because we do not review all Content, we cannot
				guarantee that content made available on our Services will
				always conform to these Terms.
			</p>

			<h3>PRIVACY</h3>

			<p>
				Your privacy is important to Yoon. Please review our Privacy
				Policy to learn more about how we handle information provided
				through the use of our Services. By using our services you agree
				to our Privacy Policy and agree that Yoon can collect, use, and
				share your information consistent with that policy. Our Privacy
				Policy can be found at www.yoondigital.com/privacypolicy.
			</p>

			<h3>RESPECTING OTHER PEOPLE’S RIGHTS</h3>

			<p>
				Yoon respects the rights of others. You may not use our
				Services, or enable anyone else to use our Services, in a way
				that:
			</p>
			<ul>
				<li>
					violates or infringes someone else’s rights of publicity,
					privacy, copyright, trademark, or other
					intellectual-property right.
				</li>
				<li>bullies, harasses, or intimidates</li>
				<li>defames</li>
				<li>spams or solicits other Yoon users</li>
			</ul>
			<p>
				You must also respect Yoon’s rights. These Terms do not grant
				you any right to do any of the following (or enable anyone else
				to do so):
			</p>
			<ul>
				<li>
					use branding, logos, designs, photographs, videos, or any
					other materials used in our Services
				</li>
				<li>
					copy, archive, download, upload, distribute, syndicate,
					broadcast, perform, display, make available, or otherwise
					use any portion of the Services or the content on the
					Services except as set forth in these Terms
				</li>
				<li>
					use the Services, any tools provided by the Services, or any
					content on the Services for any commercial purposes without
					our consent.
				</li>
			</ul>

			<p>
				You may not use our Services, or the content posted or made
				available through our Services, in ways that are not authorized
				by these Terms. Nor can you assist anyone else in doing so.
			</p>

			<h3>RESPECTING COPYRIGHT</h3>

			<p>
				Yoon honors the requirements set forth in United States
				copyright laws, including the Digital Millennium Copyright Act.
				As such, Yoon takes reasonable steps to remove from our Services
				any infringing material that we become aware of. In turn, if
				Yoon becomes aware that one of its users has repeatedly
				infringed copyrights, we will take reasonable steps within our
				power to terminate the user’s account.
			</p>

			<p>
				We make it easy for you to report suspected copyright
				infringement. If you believe that anything posted or made
				available on our Services infringes a copyright that you own or
				control, please contact our designated copyright agent:
			</p>
			<p>
				Create Music Group, Inc
				<br />
				.Attn: Copyright Agent
				<br />
				1230 N. Wilton Pl.
				<br />
				Los Angeles, CA 90028
				<br />
				Telephone: (323) 533-0704
				<br />
				Email: copyrightagent@yoondigital.com
			</p>
			<p>
				If you file a notice with our Copyright Agent, it must comply
				with the requirements set forth at 17 U.S.C. § 512(c)(3). That
				means the notice must:
			</p>
			<ul>
				<li>
					contain the physical or electronic signature of a person
					authorized to act on behalf of the copyright owner
				</li>
				<li>
					identified the copyrighted work claimed to have been
					infringed
				</li>
				<li>
					identify the material that is claimed to be infringing or to
					be the subject of infringing activity and that is to be
					removed, or access to which is to be disabled, and
					information reasonably sufficient to let us locate the
					material
				</li>
				<li>
					provide your contact information, including your address,
					telephone number, and an email address
				</li>
				<li>
					provide a personal statement that you have a good faith
					belief that the use of the material is not authorized by the
					copyright owner, its agent, or the law
				</li>
				<li>
					provide a statement that the information in the notification
					is accurate and provided under penalty of perjury
				</li>
			</ul>

			<h3>SAFETY</h3>

			<p>
				We work hard to keep Yoon safe but cannot guarantee our users’
				safety. By using our Services you agree that:
			</p>
			<ul>
				<li>
					You will not use the Services for any purpose that is
					illegal or prohibited by these Terms
				</li>
				<li>
					You will not use any robot, spider, crawler, scraper, or
					other automated means or interface to access the Services or
					extract other user’s information
				</li>
				<li>
					You will not use or develop any third-party applications
					that interact with the Services or other users’ content or
					information without our written consent
				</li>
				<li>
					You will not use the Services in a way that could interfere
					with, disrupt, negatively affect, or inhibit other users
					from fully enjoying the Services, or that could damage,
					disable, overburden, or impair the functioning of the
					Services
				</li>
				<li>
					You will not use or attempt to use another user’s account,
					username, or password without their permission
				</li>
				<li>
					You will not solicit login credentials from another user
				</li>
				<li>
					You will not post content that contains or links to nude,
					partially nude, or sexually suggestive photographs,
					pornography, graphic violence, threats, hate speech, or
					incitements to violence
				</li>
				<li>
					You will not upload viruses or other malicious code or
					otherwise compromise the security of the Services
				</li>
				<li>
					You will not attempt to circumvent any content-filtering
					techniques we employ, or attempt to access areas or features
					of the Services that you are not authorized to access
				</li>
				<li>
					You will not probe, scan, or test the vulnerability of our
					Services or any system or network
				</li>
				<li>
					You will not encourage or promote any activity that violates
					these Terms.
				</li>
			</ul>

			<h3>YOUR ACCOUNT</h3>

			<p>
				You may need to create an account to use some of our Services.
				You are responsible for safeguarding your account, so use a
				strong password and take care when accessing and using your
				account. Yoon cannot and will not be liable for any loss or
				damage arising from your failure to comply with the above.
			</p>

			<p>In addition to the above, by these Terms, you agree that:</p>
			<ul>
				<li>You will not create more than one account for yourself</li>
				<li>
					You will not buy, sell, rent, or lease access to your
					account
				</li>
				<li>You will not share your password</li>
				<li>
					You will not log in, or attempt to access, the Services
					through unauthorized third-party applications, clients or
					services.
				</li>
			</ul>

			<p>
				We may need to provide you with certain communications, such as
				service announcements and administrative messages. These
				communications are considered part of the Services and your
				account, and you may not be able to opt out of receiving them.
			</p>

			<p>
				If you believe that someone has gained access to your account,
				please immediately contact support@yoondigital.com.
			</p>

			<h3>DATA CHARGES AND MOBILE PHONES</h3>

			<p>
				You are responsible for any mobile charges that you may incur
				for using our Services, including text-messaging and data
				charges. If you are unsure what those charges might be, you
				should contact your service provider.
			</p>

			<p>
				If you change, or deactivate, a mobile phone number associated
				with your account, you must update your account information
				through the Settings within 72 hours to prevent us from sending
				messages intended for you to someone else.
			</p>

			<h3>THIRD-PARTY SERVICES</h3>

			<p>
				If you use a service, feature, or functionality that is operated
				by a third-party and made available through our Services
				(including Services we offer jointly with a third-party), each
				party’s terms will govern the respective party’s relationship
				with you. Yoon is not responsible or liable for a third-party’s
				terms and conditions or actions taken under or by the
				third-party’s terms or conditions.
			</p>

			<h3>MODIFYING THE SERVICES AND TERMINATION</h3>

			<p>
				We continue to improve, revise and develop our Services and from
				time to time will create new or additional Services. That means
				we may add or remove features, products, or functionalities, and
				we may also suspend or end the use of certain Services
				altogether. We may take these actions at any time and we may not
				provide you with notice beforehand.
			</p>

			<p>
				Yoon may also terminate these Terms with you at any time, for
				any reason, and without any notice. That means that we may stop
				providing you with any Services, or impose new or additional
				limits on your ability to use our Services. For example, we may
				deactivate your account due to prolonged in activity, and we may
				reclaim your username at any time for any reason.
			</p>

			<h3>INDEMNITY</h3>

			<p>
				You agree, to the extent permitted by law, to indemnify, defend,
				and hold harmless Yoon, its affiliates, directors, officers,
				stockholders, employees, licensors, and agents from and against
				any and all complaints, charges, claims, damages, losses, costs,
				liabilities, and expenses (including attorneys’ fees) due to,
				arising out of, or related in any way to (a) your access to, or
				use of, our Services, (b) your content, or content you post to
				our Services, and (c) your breach of these Terms.
			</p>

			<h3>DISCLAIMERS</h3>

			<p>
				We try our best to keep the Services up and running and free of
				annoyances, but we cannot promise we will always succeed.
			</p>

			<p>
				HE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE
				EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER
				EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
				WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PRUPOSE,
				TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE YOON ATTEMPTS TO
				PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT
				THAT: (A) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR
				TIMELY; (B) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS,
				DISRUPTIONS, OR IMPERFECTIONS, OR (C) THAT ANY CONTENT, USER
				CONTENT, OR INFORMATION YOU OBTAIN ONN, OR THROUGH, THE SERVICES
				WILL BE TIMELY OR ACCURATE.
			</p>

			<p>
				YOON TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY
				CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES,
				UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR
				SERVICES. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO
				CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR
				OTHERWISE INAPPROPRIATE, NONE OF WHICH YOON WILL BE RESPONSIBLE
				FOR.
			</p>

			<h3>LIMITATION OF LIABILITY</h3>

			<p>
				TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOON, AND OUR MANAGING
				MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS, AGENTS,
				AND SUPPLIERS (COLLECTIVELY, “AFFILIATES”) WILL NOT BE LIABLE
				FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE,
				OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
				INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
				GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR
				ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES,
				(B) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR
				THROUGH THE SERVICES, OR (C) UNAUTHORIZED ACCESS, USE, OR
				ALTERATION OF YOUR CONTENT, EVEN IF YOON HAS BEEN ADVISED OF THE
				POSSIBILITY OF SUCH DAMAGES. IN NO EVEN WILL YOON’S AGGREGATE
				LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE
				GREATER OF ONE HUNDRED U.S. DOLLARS ($100 USD) OR THE AMOUNT YOU
				PAID YOON, IF ANY, IN THE LAST TWELVE (12) MONTHS.
			</p>

			<h3>WAIVER</h3>

			<p>
				TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU WAIVE AND DISCLAIM
				YOUR ABILITY TO INSTITUTE AN ACTION FOR INJUNCTION RELIEF AND/OR
				TO FORM A CLASS AND INSTITUTE A CLASS ACTION AGAINST YOON AND
				ITS AFFILIATES.
			</p>

			<h3>DISPUTES</h3>

			<p>
				You agree to resolve any claim, cause of action or dispute
				(claim) you have with Yoon arising out of, or relating to, these
				Terms or Yoon exclusively in mandatory binding arbitration
				before JAMS in Los Angeles, California. The laws of the State of
				California will govern this Agreement and any claim that might
				arise between you and Yoon, without regard to its choice of law
				principles.
			</p>

			<h3>ASSIGNMENT</h3>

			<p>
				Yoon may assign this Agreement and/or the Services it provides,
				at any time. Regardless, your rights under this Agreement are
				personal to you and are not assignable.
			</p>

			<h3>SEVERABILITY</h3>

			<p>
				If any provision of these Terms is found unenforceable, then
				that provision will be severed from these Terms and not affect
				the validity and enforceability of any remaining provisions.
			</p>

			<h3>CONTACT</h3>

			<p>
				Yoon welcomes comments, questions, concerns, or suggestions.
				Please send us feedback at support@yoondigital.com.
			</p>

			<p>
				Yoon is located in the United States at 1320 N. Wilton Pl., Los
				Angeles, CA 90028.
			</p>
		</div>
	);
};

export default TermsOfService;
