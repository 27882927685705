import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./NewUserLandingPage.scss";
import arrow from "../images/arrow.png";

const NewUserLandingPage = () => {
	return (
		<div className="new-user-page-container">
			<div className="new-user-page-section section-b">
				<h1>Welcome to Yoon!</h1>
				<div className="new-user-page-section-text">
					<p>We're so happy to have you on board!</p>
				</div>
				<Link to="/dashboard">
					<Button
						variant="primary"
						type="submit"
						className="submit-btn"
					>
						<span>Get Started Now!</span>
						<span className="arrow-container">
							<img src={arrow} alt="arrow icon" />
						</span>
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default NewUserLandingPage;
