import Button from "react-bootstrap/Button";
import React, { useState, Fragment } from "react";
import "./Upload.scss";
import UploadPageOne from "./UploadPageOne";
import UploadPageTwo from "./UploadPageTwo";
import UploadPageThree from "./UploadPageThree";
import {
	submitVideo,
	updateUploads,
	getReferrer,
} from "../services/submission-requests";
import { getProfile } from "../services/user-requests";
import { useAlert } from "react-alert";
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";

const UploadForm = (props) => {
	const { activeUser, setActiveSection } = props;
	const alert = useAlert();
	const [page, setPage] = useState(1);
	const [fileName, setFileName] = useState("");
	const [file, setFile] = useState(null);
	const [progress, setProgress] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const submitPageOne = (images) => {
		setFileName(images[0].name);
		setFile(images[0]);
		setPage(2);
	};

	const submitPageTwo = async (values) => {
		setProgress(0);
		setIsLoading(true);
		try {
			setProgress(10);
			const cloudinaryResp = await submitFile();
			setProgress(60);
			values.cloudinary_id = cloudinaryResp.data.public_id;
			const referrerData = await getReferrer(activeUser.user_id);
			const profileData = await getProfile(activeUser.user_id);
			console.log(referrerData);
			console.log(referrerData[0].referrer);
			console.log(profileData);
			const socialData = {
				facebook: profileData.facebook,
				twitter: profileData.twitter,
				instagram: profileData.instagram,
				youtube: profileData.youtube,
			};
			await submitVideo(
				values,
				activeUser,
				fileName,
				referrerData[0].referrer,
				referrerData[0].partner_status,
				referrerData[0].channel_id,
				referrerData[0].channel_title,
				socialData
			);
			setProgress(90);
			await updateUploads(activeUser.email);
			setProgress(100);
			const finish = () => {
				setProgress(null);
				alert.success("Upload complete!");
				setIsLoading(false);
				setPage(3);
			};
			setTimeout(finish, 500);
		} catch (err) {
			console.log(err);
			setIsLoading(false);
			setProgress(null);
			if (err.response) {
				console.log(err.response);
				console.log(err.response.status);
				if (
					err.response.status === 400 &&
					err.response.data !==
						"An unexpected error occurred. Please try again."
				) {
					alert.error(
						"File size too large. Please do not exceed 100mb upload size"
					);
				} else {
					alert.error("An unknown error occurred. Please try again.");
				}
			} else {
				alert.error("An unknown error occurred. Please try again.");
			}
		}
	};
	const submitFile = () => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("api_key", "292245126333841");
		formData.append("timestamp", (Date.now() / 1000) | 0);
		formData.append("upload_preset", "ksvyvfu5");
		return axios.post(
			"https://api.cloudinary.com/v1_1/yoon-digital/video/upload",
			formData,
			{ headers: { "X-Requested-With": "XMLHttpRequest" } }
		);
	};
	return (
		<Fragment>
			<div className="upload-nav-bar">
				<div
					className={`pointer upload-item${
						page === 1 ? " active" : ""
					}`}
					onClick={() => setPage(1)}
				>
					<span className="nav-text">
						{window.innerWidth > 767
							? "01. SELECT VIDEO"
							: "01. VIDEO"}
					</span>
				</div>
				<div className={`upload-item${page === 2 ? " active" : ""}`}>
					<span className="nav-text">
						{window.innerWidth > 767
							? "02. ADD DETAILS"
							: "02. DETAILS"}
					</span>
				</div>
				<div className={`upload-item${page === 3 ? " active" : ""}`}>
					<span className="nav-text">03. SUCCESS!</span>
				</div>
			</div>
			<div className="upload-form-container">
				{page === 1 ? (
					<UploadPageOne submitPageOne={submitPageOne} />
				) : null}
				{page === 2 ? (
					<UploadPageTwo
						activeUser={activeUser}
						alert={alert}
						fileName={fileName}
						setFileName={setFileName}
						submitFile={submitFile}
						submitPageTwo={submitPageTwo}
						progress={progress}
						isLoading={isLoading}
					/>
				) : null}
				{page === 3 ? (
					<UploadPageThree setActiveSection={setActiveSection} />
				) : null}
			</div>
		</Fragment>
	);
};
export default UploadForm;
