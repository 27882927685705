import React from "react";
import SbEditable from "storyblok-react";
import PropTypes from "prop-types";
import Logo from "./Logo";
import InfoBlockWithImage from "./InfoBlockWithImage";

// resolve Storyblok components to Next.js components
const Components = {
    // PetsOnQ
    Logo,
    InfoBlockWithImage,
};

const DynamicComponent = ({ blok, ...otherProps }) => {
    // check if component is defined above
    if (typeof Components[blok.component] !== "undefined") {
        const Component = Components[blok.component];
        // wrap with SbEditable for visual editing
        return (
            <SbEditable content={blok}>
                <Component blok={blok} otherProps={otherProps} />
            </SbEditable>
        );
    }
    return (
        <p>
            The component <strong>{blok.component}</strong> has not been created
            yet.
        </p>
    );
};

DynamicComponent.defaultProps = {
    blok: {},
};

DynamicComponent.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]),
};

export default DynamicComponent;
