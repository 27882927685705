import React, { Fragment, useEffect } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./Auth.scss";
import {
	handleRegister,
	handleLogIn,
	ipLookUp,
	checkIfBanned,
	oAuthSignIn,
} from "../services/auth-requests";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import arrow_black from "../images/arrow-blk.png";
import arrow_yellow from "../images/arrow-yellow.png";

const Register = () => {
	const alert = useAlert();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const responseFacebook = (response) => {
		if (response.email) {
			handleOAuthRegister(
				response.email,
				response.id,
				response.accessToken,
				"facebook"
			);
		} else {
			console.log(response);
		}
	};
	const responseGoogle = (response) => {
		if (response.profileObj) {
			handleOAuthRegister(
				response.profileObj.email,
				response.googleId,
				response.tokenObj.access_token,
				"google"
			);
		} else {
			console.log(response);
		}
	};
	const handleOAuthRegister = async (email, id, accessToken, method) => {
		const isBanned = await checkIfBanned();
		if (isBanned.data.banned) {
			alert.error("Account or IP has been banned");
			return;
		}
		const oAuthRegisterFn = (city, region, country, addressIP) => {
			const referrer = window.localStorage.getItem("referrer");
			return oAuthSignIn(
				email,
				accessToken,
				city,
				region,
				country,
				addressIP,
				referrer,
				method
			).then((result) => {
				console.log(result);
				if (result.message === "User hasn't agreed to terms yet") {
					window.location.href = `/terms-and-conditions?user=${email}&id=${id}&method=${method}`;
				} else if (result.success) {
					window.location.href = "/dashboard";
				} else {
					alert.error(result.message);
				}
			});
		};

		ipLookUp()
			.then((data) => {
				const { city, region, country_name } = data;
				const addressIP = data.ip;
				oAuthRegisterFn(city, region, country_name, addressIP);
			})
			.catch((err) => {
				oAuthRegisterFn();
			});
	};
	return (
		<Fragment>
			<div className="auth-container">
				<div className="bg-image register" />
				<h1 className="auth-form-h1">Sign Up</h1>
				<div className="first halves">
					<div className="left-half">
						<Formik
							initialValues={{
								email: "",
								password: "",
								agree: "",
								adult: "",
							}}
							validate={(values) => {
								const {
									email,
									password,
									agree,
									adult,
								} = values;
								let errors = {};
								if (!email) {
									errors.email = "Required";
								} else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
										email
									)
								) {
									errors.email = "Invalid email address";
								}
								const hasNumbers = /\d/.test(password);
								const hasNonalphas = /\W/.test(password);

								if (
									password.length < 6 ||
									!(hasNumbers || hasNonalphas)
								) {
									errors.password =
										"Password must be at least 6 characters and contain either a number or a special character.";
								}
								if (!password) {
									errors.password = "Required";
								}

								return errors;
							}}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={async (values, { setSubmitting }) => {
								const {
									email,
									password,
									agree,
									adult,
								} = values;

								if (!validateAgreeAdult(agree, adult)) return;
								const registerFn = (
									city,
									region,
									country,
									addressIP
								) => {
									const referrer = window.localStorage.getItem(
										"referrer"
									);
									return handleRegister(
										email,
										password,
										city,
										region,
										country,
										addressIP,
										referrer
									).then((result) => {
										if (result.success) {
											window.location.href = "/new-user";
											setSubmitting(false);
										} else {
											alert.error(result);
											setSubmitting(false);
										}
									});
								};
								try {
									const ipData = await ipLookUp();
									const {
										city,
										country_name,
										region,
										ip,
									} = ipData;
									const isBanned = await checkIfBanned(ip);
									if (isBanned.data.banned) {
										alert.error(
											"Account or IP has been banned"
										);
										setSubmitting(false);
										return;
									}
									await registerFn(
										city,
										region,
										country_name,
										ip
									);
								} catch (err) {
									registerFn();
								}
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
							}) => (
								<Form noValidate onSubmit={handleSubmit}>
									<h2 className="auth-form-heading">
										with Email
									</h2>
									<Form.Group
										className="form-group-custom"
										controlId="formBasicEmail"
									>
										<div className="label-container">
											<Form.Label>
												Email address
											</Form.Label>
											<div className="errors">
												{errors.email}
											</div>
										</div>
										<Form.Control
											className="auth-text-input"
											type="text"
											name="email"
											value={values.email}
											onChange={handleChange}
											isInvalid={!!errors.email}
											placeholder="Enter email"
										/>
									</Form.Group>
									<Form.Group
										className="form-group-custom"
										controlId="formBasicPassword"
									>
										<div className="label-container">
											<Form.Label>Password </Form.Label>
											<div className="errors">
												{errors.password}
											</div>
										</div>
										<Form.Control
											className="auth-text-input"
											type="password"
											name="password"
											value={values.password}
											onChange={handleChange}
											isInvalid={!!errors.password}
											placeholder="Password"
										/>
									</Form.Group>
									<Form.Group
										className="form-group-custom form-check"
										controlId="formBasicAgree"
									>
										<label className="agree check">
											I agree to the terms and conditions
											<input
												name="agree"
												type="checkbox"
												value={values.agree}
												onChange={handleChange}
											/>
											<span className="checkmark"></span>
										</label>
									</Form.Group>
									<Form.Group
										className="form-group-custom form-check"
										controlId="formBasicAdult"
									>
										<label className="adult check">
											I am 18 years old or older
											<input
												name="adult"
												type="checkbox"
												value={values.adult}
												onChange={handleChange}
											/>
											<span className="checkmark"></span>
										</label>
									</Form.Group>
									<div className="form-container-centered form-submit-container">
										<Button
											variant="primary"
											className="relative-btn"
											type="submit"
										>
											<span className="text">
												Sign Up
											</span>
											<span className="arrow">
												<img src={arrow_black} />
											</span>
										</Button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
					<div className="right-half">
						<h2 className="auth-form-heading">OR...</h2>
						<div className="form-container-centered">
							<FacebookLogin
								appId="1197671610420193"
								fields="name,email"
								callback={responseFacebook}
								isMobile={false}
								disableMobileRedirect={true}
								render={(renderProps) => (
									<button
										className="oauth-btn fb-login relative-btn"
										onClick={renderProps.onClick}
									>
										<span className="text">
											Sign Up with Facebook
										</span>
										<span className="arrow">
											<img src={arrow_black} />
										</span>
									</button>
								)}
							/>
						</div>
						<div className="form-container-centered">
							<GoogleLogin
								clientId="677427210058-lp41b6d082s34cdb46qfjf83u234kunq.apps.googleusercontent.com"
								render={(renderProps) => (
									<button
										className="oauth-btn google-login relative-btn"
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
									>
										<span className="text">
											Sign Up with Google/Youtube
										</span>
										<span className="arrow">
											<img src={arrow_black} />
										</span>
									</button>
								)}
								autoLoad={false}
								onSuccess={responseGoogle}
								onFailure={responseGoogle}
								cookiePolicy={"single_host_origin"}
							/>
						</div>
					</div>
				</div>
				<div className="second halves">
					<div className="left-half links">
						<Link to="/copyright-policy">
							contributor agreement
						</Link>
						<Link to="/terms-of-service">terms of service</Link>
						<Link to="/privacy-policy">privacy policy</Link>
					</div>
					<div className="right-half sign-up-container">
						<span>Already a part of Yoon?</span>
						<Link to="/login">
							<div className="register-login-link">
								<span className="text">Login Now +++++</span>
								<span className="arrow">
									<img src={arrow_yellow} />
								</span>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const validateAgreeAdult = (agree, adult) => {
	if (!agree && !adult) {
		document
			.querySelector(".agree.check .checkmark")
			.classList.add("is-invalid");
		document
			.querySelector(".adult.check .checkmark")
			.classList.add("is-invalid");
		return false;
	}
	if (!agree) {
		document
			.querySelector(".agree.check .checkmark")
			.classList.add("is-invalid");
		if (!adult) {
			document
				.querySelector(".adult.check .checkmark")
				.classList.add("is-invalid");
			return false;
		} else {
			document
				.querySelector(".adult.check .checkmark")
				.classList.remove("is-invalid");
		}
		return false;
	} else {
		document
			.querySelector(".agree.check .checkmark")
			.classList.remove("is-invalid");
	}

	if (!adult) {
		document
			.querySelector(".adult.check .checkmark")
			.classList.add("is-invalid");
		return false;
	} else {
		document
			.querySelector(".adult.check .checkmark")
			.classList.remove("is-invalid");
	}
	return true;
};
export default Register;
