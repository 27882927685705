import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./PolicyPages.scss";
const CopyrightPolicy = ({ activeUser }) => {
	const [hasLoaded, setHasLoaded] = useState(false);
	const [isReferrer, setIsReferrer] = useState(false);
	const [content, setContent] = useState(null);
	const getStoryblokData = async () => {
		console.log("window.StoryblokClient", window.StoryblokClient);
		console.log("activeUser", activeUser);
		if (window.StoryblokClient) {
			if (
				activeUser.referrer ||
				window.localStorage.getItem("referrer")
			) {
				let Storyblok = new window.StoryblokClient({
					accessToken: "MrhjwmftQvz8EQnieRzihwtt",
					cache: {
						clear: "auto",
						type: "memory",
					},
				});
				try {
					let { data } = await Storyblok.get(
						`cdn/stories/contributor-agreements/${
							activeUser.referrer
								? activeUser.referrer
								: window.localStorage.getItem("referrer")
						}`,
						{
							version: "draft", // or 'published'
						}
					);
					console.log(data.story.content.long_text);
					setContent({
						__html: Storyblok.richTextResolver.render(
							data.story.content.long_text
						),
					});
					setIsReferrer(true);
					setHasLoaded(true);
				} catch (err) {
					// load the default one
					console.log(err);
					setHasLoaded(true);
				}
			} else {
				setHasLoaded(true);
			}
		} else {
			setTimeout(getStoryblokData, 1000);
		}
	};
	useEffect(() => {
		getStoryblokData();
	}, []);
	return (
		<Fragment>
			<Helmet>
				<script src="https://cdn.jsdelivr.net/npm/storyblok-js-client@4.0.5/dist/index.standalone.js"></script>
			</Helmet>
			{hasLoaded ? (
				isReferrer ? (
					<div
						className="policy-container contrib-agreement"
						dangerouslySetInnerHTML={content}
					/>
				) : (
					<div className="policy-container contrib-agreement">
						<h1>YOON CONTRIBUTOR AGREEMENT</h1>

						<p>
							By uploading a video which shall mean audio, visual
							and audio-visual content which you own, control,
							license, or to which you otherwise claim rights,
							during the term of this agreement, including all
							such content appearing on any Youtube channels,
							Facebook pages, or other social media platforms you
							choose to partner (the “Video”) to the Yoon website,
							you are agreeing to the following terms:
						</p>
						<ol>
							<li>
								<u>Grant of Rights.</u> You hereby grant Create
								Music Group, Inc. (“CMG”) the exclusive right to
								publish, distribute, reproduce, publicly
								display, broadcast, license and otherwise
								monetize the Video throughout the universe in
								all media during the Term for any purpose. Such
								rights will include the right to edit, modify,
								alter, and add to the Video in the sole
								discretion of CMG. CMG may also use the Video in
								its sole discretion to advertise, market, and
								promote itself and/or third parties. CMG has
								sole and absolute discretion to set the
								licensing fees or other remuneration it receives
								for third party use of the Video. CMG has the
								right, but not the obligation, in its sole
								discretion to perform content claiming
								activities concerning the Video across any and
								all social media platforms and to institute any
								copyright actions concerning the Video. You will
								cooperate with CMG as requested in order to
								further the rights you have granted to CMG
								pursuant to this Agreement. You further hereby
								appoint CMG as your attorney-in-fact to take any
								action it deems as reasonably necessary to
								further its rights under this Agreement.
							</li>
							<li>
								<p>
									<u>Payment</u>
								</p>
								<p>
									a. CMG shall remit to you fifty percent
									(50%) of all Net Revenue it collects in
									connection with the Video. For the purposes
									of this Agreement, Net Revenue shall be
									defined as all monies actually collected by
									CMG that are directly attributed to the
									Video less CMG’s costs and expenses,
									including but not limited to any value added
									tax (“VAT”) or tax withholding. CMG shall
									remit such payments to you on a monthly
									within thirty (30) days of the end of the
									month, provided such payments total in
									excess of Fifty U.S. Dollars (US $50.00).
									All payments will be remitted to you via
									Paypal to the account you indicate to us
									upon your initial uploading of the Video.
								</p>
								<p>
									b. After deducting an administration fee,
									CMG will pay you the following royalties: If
									any direct ads are sold (in addition to the
									Google Ads sold), an amount equal to [25]%
									of direct ad net revenue received by CMG
									from your Videos on your YouTube channels or
									on a CMG channel.
								</p>
							</li>
							<li>
								<p>
									<u>
										Your Representations, Warranties, and
										Covenants
									</u>
									. You hereby represent, warrant, and
									covenant as follows:
								</p>
								<p>a. You own the Video.</p>
								<p>
									b. You have not previously licensed the
									Video to any other person or entity and
									during the Term you will not license the
									Video to any other person or entity.
								</p>
								<p>
									c. No person or entity has claimed ownership
									in the Video or any of its elements.
								</p>
								<p>
									d. You have the right to use all footage and
									performances captured by the Video.
								</p>
								<p>
									e. The Video does not infringe upon the
									copyrights, trademarks, rights of publicity
									or rights of privacy of any person or
									entity.
								</p>
								<p>
									f. The Video does not defame any person or
									entity.
								</p>
								<p>
									g. The Video does not portray any illicit
									conduct.
								</p>
								<p>
									h. The Video does not portray any sexually
									explicit words or conduct.
								</p>
								<p>
									i. The Video does not portray alcohol or
									tobacco use by any individual under
									twenty-one (21) years of age.
								</p>
								<p>
									j. The Video does not use any music that is
									not owned by You.
								</p>
								<p>
									k. The Video does not contain a performance
									by any SAG-AFTRA member.
								</p>
							</li>
							<li>
								<u>Clearance.</u> You are solely responsible for
								clearing the use of all performances and third
								party materials used in the Video and, if
								applicable, paying any compensation or royalties
								due to third parties. Upon request, Licensor
								shall provide copies of its releases and/or
								licenses of such third party materials or
								performances.
							</li>
							<li>
								<u>Indemnification.</u> You hereby indemnify,
								defend and hold harmless CMG from any liability
								arising out of CMG’s use of the Video or your
								breach of any part of this Agreement.
							</li>
							<li>
								<u>Limitation of Liability/Waiver</u>. Each
								party’s liability under this Agreement will be
								limited to actual, direct damages, provided that
								CMG may pursue injunctive relief in the event
								you breach your obligations under this
								Agreement. Neither party will pursue an action
								for indirect, consequential, incidental,
								nominal, special or punitive damages. You
								further waive all rights to any injunctive
								relief, to any claim for attribution, and for
								any claim of moral rights.
							</li>
							<li>
								<u>
									NO REPRESENTATION REGARDING MONETIZATION OR
									FITNESS FOR A PARTICULAR PURPOSE
								</u>
								. CMG MAKES NO REPRESENTATION, WARRANTY OR
								COVENANT TO YOU, EXPRESS OR IMPLIED REGARDING
								ITS ABILITY TO SUCCESSFULLY TO MONETIZE OR
								LICENSE THE VIDEO. MOREOVER, CMG MAKES NO
								REPRESENTATION, WARRANTY OR COVENANT, EXPRESS OR
								IMPLIED, TO YOU AS TO THE QUALITY, PERFORMANCE,
								MERCHANTABILITY OR FITNESS FOR A PARTICULAR
								PURPOSE OF ITS SERVICES OR ANY ELEMENTS OF ITS
								SERVICES.
							</li>
							<li>
								<u>Relationship of the Parties</u>. In entering
								into this Agreement, CMG and you have and will
								have the status of independent contractors.
								Accordingly, there is no joint venture,
								partnership, agency or fiduciary relationship
								existing between the parties, and the parties do
								not intend to create any such relationship by
								this Agreement.
							</li>
							<li>
								<u>Assignment.</u> All of CMG’s rights under
								this Agreement, and each part thereof, are fully
								sublicensable and assignable. You may not assign
								your rights under this Agreement without the
								prior written consent of CMG.
							</li>
							<li>
								<u>Term/Termination.</u> This Agreement is
								effective immediately upon submission of the
								Video and shall continue until terminated. CMG
								may terminate this Agreement at any time. You
								may terminate this Agreement upon thirty (30)
								days written notice. Termination shall not
								affect any license entered into prior to the end
								of the Term. Sections 4 through 14 shall survive
								termination of this Agreement.
							</li>
							<li>
								<u>Severance.</u> In the event that any
								provision of this Agreement is deemed
								unenforceable, that provision shall be severed
								and the remainder of the Agreement shall remain
								in full force and effect.
							</li>
							<li>
								<u>Governing Law/Arbitration.</u> This Agreement
								shall be interpreted under the laws of the state
								of California without regard to conflicts of law
								principles. Any dispute between you and CMG
								arising out of or related to this Agreement is
								subject to mandatory binding arbitration before
								JAMS in Los Angeles, California and both parties
								hereby irrevocably submit to JAM’s jurisdiction
								over such disputes.
							</li>
							<li>
								<u>Amendment.</u> This Agreement is binding upon
								the parties and their assignees. This Agreement
								may not be amended except through a written
								instrument executed by the parties, provided
								that CMG may amend this Agreement from time to
								time by sending out a notice to you at least
								sixty (60) days prior to the amendment taking
								place. If you do not wish to accept the
								amendment, you may terminate the Agreement prior
								to the effective date of the amendment by giving
								written notice. Such termination will be
								effective according to the provisions of Section
								6.
							</li>
							<li>
								<u>Notice Via Email.</u> You agree to be
								notified of any issues pertaining to your Video
								or the parties obligations under this Agreement
								via the email address you have provided to CMG.
								You may notify CMG of any issues pertaining to
								your Video or the parties obligations under this
								Agreement by sending an email to
								support@yoondigital.com. Such email shall
								include your name, email address, name of the
								video, and link to the video on YouTube or other
								applicable social media platform.
							</li>
							<li>
								<p>
									<u>Additional terms and Conditions:</u>
								</p>
								<p>
									a. <u>Your Obligations.</u>During the Term,
									you will make best efforts to: (a) Promptly
									take all such action as may be required by
									CMG in order to permit: (i) your Videos to
									be included in CMG’ss CMS; and (ii) the
									views of your Videos to be included or
									“rolled up” exclusively into CMG’s views,
									impressions, and other reporting metrics:
									(b) CMG acquire your Videos on a frequency
									and at a level of quality consistent with
									any other content uploaded to your YouTube
									channels prior to the Effective Date, and
									promptly upload them to your YouTube
									channels; (c) maintain and manage the
									development of your YouTube channels; and
									(d) for the purposes of CMG claiming your
									Videos on YouTube, provide to CMG upon CMG’s
									request metadata for your Videos not
									uploaded onto your YouTube channels in a
									form acceptable to CMG.
								</p>
								<p>
									b. <u>Your Conduct.</u>
								</p>
								<p className="ml-4">
									(a) Required and Prohibited Conduct. During
									the term, you will (in addition to your
									other obligations set out elsewhere in this
									agreement):
								</p>
								<p className="ml-8">
									i. Comply with all: (A) policies and
									procedures of CMG as may be in effect from
									time to time, including CMG’s or Yoon’s
									Terms of Service displayed on their
									websites, and CMG or Yoon’s Privacy Policy
									displayed on their websites all of which are
									incorporated herein by this reference; (B)
									applicable policies, procedures, or End User
									agreements and privacy policies of your
									YouTube Channels and (C) applicable laws,
									rules, and regulations;
								</p>
								<p className="ml-8">
									ii. Not, and will not authorize any third
									party to, distribute any content or attempt
									to monetize any content that infringes on
									the rights of others or violates any content
									guidelnes; and
								</p>
								<p className="ml-8">
									iii. Not, and will not authorize or
									encourage any third party to, directly or
									indirectly generate queries, impressions of
									or clicks on any ad(s) or to obtain access
									to your Videos through: (A) any automated,
									deceptive, fraudulent or other invalid
									means, including through repeated manual
									clicks, the use of robots or other automated
									query tools and/or computer generated search
									requests, or the fraudulent use of other
									search engine optimization services and/or
									software; (B) clicks or impressions
									originating from your IP address or your
									computers; (C) the payment of money, false
									representation or requests for users to
									click on ads; or (D) any other means
									designed to imitate a legitimate End User or
									otherwise skew results.
								</p>
								<p className="ml-4">
									c. Consider your Videos for: (i) the sale of
									direct ads; (ii) the sale of branded
									entertainment arrangements; and for other
									YouTube opportunities, if applicable; and
									(iii) distribution on one or more CMG
									channels (such distribution, if any, shall
									be subject to your prior written approval.
								</p>
							</li>
						</ol>
					</div>
				)
			) : null}
		</Fragment>
	);
};

export default CopyrightPolicy;
