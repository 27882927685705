import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./Auth.scss";
import { oAuthAgree } from "../services/auth-requests";
import { useAlert } from "react-alert";
import arrow_black from "../images/arrow-blk.png";
import { Link } from "react-router-dom";
const TermsAndConditions = props => {
	const alert = useAlert();
	const params = new URLSearchParams(props.location.search);
	let user = params.get("user");
	const id = params.get("id");
	const method = params.get("method");
	console.log(user, id);
	return (
		<div className="auth-container single-form">
			<div className="bg-image login" />
			<div className="toc-form">
				<Formik
					initialValues={{
						agree: "",
						adult: ""
					}}
					validate={values => {
						const { agree, adult } = values;
						let errors = {};
						return errors;
					}}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={(values, { setSubmitting }) => {
						if (!validateAgreeAdult(values.agree, values.adult))
							return;
						oAuthAgree(user).then(result => {
							if (result.success) {
								window.location.href = "/new-user";
								setSubmitting(false);
							} else {
								alert.error(result);
								setSubmitting(false);
							}
						});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group
								className="form-group-custom form-check"
								controlId="formBasicAgree"
							>
								<label className="agree check">
									I have read and agree to the{" "}
									<Link
										to="/terms-of-services"
										target="_blank"
										className="tos-link"
										style={{ textDecoration: "underline" }}
									>
										Terms of Service
									</Link>{" "}
									&{" "}
									<Link
										to="/copyright-policy"
										target="_blank"
										className="tos-link"
										style={{ textDecoration: "underline" }}
									>
										Contributor Agreement
									</Link>
									<input
										name="agree"
										type="checkbox"
										value={values.agree}
										onChange={handleChange}
									/>
									<span className="checkmark"></span>
								</label>
							</Form.Group>
							<Form.Group
								className="form-group-custom form-check"
								controlId="formBasicAdult"
							>
								<label className="adult check">
									I am 18 years old or older
									<input
										name="adult"
										type="checkbox"
										value={values.adult}
										onChange={handleChange}
									/>
									<span className="checkmark"></span>
								</label>
							</Form.Group>
							<div className="form-container-centered form-submit-container">
								<Button
									variant="primary"
									className="relative-btn"
									type="submit"
								>
									<span className="text">Submit</span>
									<span className="arrow">
										<img src={arrow_black} />
									</span>
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

const validateAgreeAdult = (agree, adult) => {
	if (!agree && !adult) {
		document
			.querySelector(".agree.check .checkmark")
			.classList.add("is-invalid");
		document
			.querySelector(".adult.check .checkmark")
			.classList.add("is-invalid");
		return false;
	}
	if (!agree) {
		document
			.querySelector(".agree.check .checkmark")
			.classList.add("is-invalid");
		if (!adult) {
			document
				.querySelector(".adult.check .checkmark")
				.classList.add("is-invalid");
			return false;
		} else {
			document
				.querySelector(".adult.check .checkmark")
				.classList.remove("is-invalid");
		}
		return false;
	} else {
		document
			.querySelector(".agree.check .checkmark")
			.classList.remove("is-invalid");
	}

	if (!adult) {
		document
			.querySelector(".adult.check .checkmark")
			.classList.add("is-invalid");
		return false;
	} else {
		document
			.querySelector(".adult.check .checkmark")
			.classList.remove("is-invalid");
	}
	return true;
};

export default TermsAndConditions;
