import React, { useState, Fragment } from "react";
import { handleLogOut } from "../services/auth-requests.js";
import logo_m from "../images/logo_m.png";
import menu from "../images/menu.png";
import Sidebar from "react-sidebar";
import { Link } from "react-router-dom";

const MenuMobile = ({ activeSection, setActiveSection, children }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleOpen = (open) => {
		setMenuOpen(open);
	};
	return (
		<Fragment>
			<div className="menu-left mobile">
				<div className="menu-1">
					<div style={{ width: "75px" }} />
					<div className="top">
						<div className="logo-container">
							<img src={logo_m} />
						</div>
					</div>
					<div className="bottom">
						<div
							className="menu-icon-container"
							onClick={() => toggleOpen(true)}
						>
							<img src={menu} />
						</div>
					</div>
				</div>
			</div>
			<Sidebar
				sidebar={
					<SidebarContent
						activeSection={activeSection}
						setActiveSection={setActiveSection}
						toggleOpen={toggleOpen}
					/>
				}
				open={menuOpen}
				onSetOpen={toggleOpen}
				styles={{
					sidebar: {
						background: "white",
						zIndex: "5",
						position: "fixed",
						width: "75vw",
					},
				}}
			>
				{children}
			</Sidebar>
		</Fragment>
	);
};

const SidebarContent = ({ activeSection, setActiveSection, toggleOpen }) => {
	return (
		<div className="menu-2">
			<div className="menu-content">
				<div className="top upper">
					<div
						className={`pointer grey menu-item${
							activeSection === "dashboard" ? " active" : ""
						}`}
						onClick={() => {
							setActiveSection("dashboard");
							toggleOpen(false);
						}}
					>
						Dashboard
					</div>
					<div
						className={`pointer grey menu-item${
							activeSection === "upload" ? " active" : ""
						}`}
						onClick={() => {
							setActiveSection("upload");
							toggleOpen(false);
						}}
					>
						Upload
					</div>
					<div
						className={`pointer grey menu-item${
							activeSection === "videos" ? " active" : ""
						}`}
						onClick={() => {
							setActiveSection("videos");
							toggleOpen(false);
						}}
					>
						My Videos
					</div>
					<div
						className={`pointer grey menu-item${
							activeSection === "earnings" ? " active" : ""
						}`}
						onClick={() => {
							setActiveSection("earnings");
							toggleOpen(false);
						}}
					>
						My Earnings
					</div>
					<div
						className={`pointer grey menu-item${
							activeSection === "account" ? " active" : ""
						}`}
						onClick={() => {
							setActiveSection("account");
							toggleOpen(false);
						}}
					>
						My Account
					</div>
					<div
						className={`yt-partner-link pointer grey menu-item${
							activeSection === "youtube_partner" ? " active" : ""
						}`}
						onClick={() => {
							setActiveSection("youtube_partner");
							toggleOpen(false);
						}}
					>
						Youtube Partner Program
					</div>
					<div
						className="pointer grey menu-item"
						onClick={() => {
							handleLogOut().then(
								(res) => (window.location.href = "/")
							);
						}}
					>
						Log Out
					</div>
				</div>
				<div className="bottom">
					<Link className="menu-item grey" to="/copyright-policy">
						Contributor agreement
					</Link>
					<Link className="menu-item grey" to="/terms-of-service">
						Terms of service
					</Link>
					<Link className="menu-item grey" to="/privacy-policy">
						Privacy Policy
					</Link>
				</div>
			</div>
		</div>
	);
};
export default MenuMobile;
