import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const InfoBlockWithImage = ({ blok, otherProps }) => {
	console.log(otherProps);
	return (
		<div
			className={`desc-block ${blok.reverse ? "reverse" : ""} ${
				!blok?.image?.filename ? "no-video" : ""
			}`}
		>
			<div className="desc-cta">
				<h2
					style={{ color: blok.titleColor }}
					dangerouslySetInnerHTML={{ __html: blok.title }}
				/>
				<p
					className="extra-margin"
					style={{ color: blok.descriptionColor }}
					dangerouslySetInnerHTML={{ __html: blok.description }}
				/>
				<a href={`/register/partner?r=${otherProps.handle}`}>
					<Button
						style={{
							backgroundColor: blok.buttonColor,
							borderColor: blok.buttonColor,
						}}
					>
						<span>Join Now</span>
					</Button>
				</a>
			</div>
			{blok?.image?.filename ? (
				<div className="image-cont">
					{blok.image.filename.includes(".mp4") ? (
						<video autoPlay muted loop>
							<source
								src={blok.image.filename}
								type="video/mp4"
							/>
						</video>
					) : (
						<img src={blok.image.filename} alt={blok.image.alt} />
					)}
				</div>
			) : null}
		</div>
	);
};
export default InfoBlockWithImage;
