import React, { Fragment, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import arrow from "../images/arrow.png";
import { Link } from "react-router-dom";
import { MobileView } from "react-device-detect";

const UploadPageOne = ({ toggleUploadModal, submitPageOne }) => {
	const [files, setFiles] = useState([]);
	const onDrop = useCallback((accFiles) => {
		setFiles(accFiles);
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});
	return (
		<Formik
			initialValues={{
				agree: false,
				adult: false,
				upload: null,
			}}
			validate={(values) => {
				let errors = {};
				if (!values.agree) {
					errors.agree = "Required";
				}
				if (!values.adult) {
					errors.adult = "Required";
				}
				if (!files.length) {
					errors.upload = "Required";
					document.querySelector(
						".dropzone-container .dropzone .dropzone-input"
					).style.borderColor = "red";
				}
				return errors;
			}}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={(values, { setSubmitting }) => {
				submitPageOne(files);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<Form noValidate onSubmit={handleSubmit}>
					<div className="form-text">
						<h3>Let’s start by putting up your first clip:</h3>
						<p className="grey">
							We accept .mp4 & .mpeg file formats. <br />
							Maximum file size 100MB.
						</p>
						<MobileView>
							<p className="grey">
								** Uploading is a data-intensive process. We
								recommend a Wi-Fi connection for this.
							</p>
						</MobileView>
					</div>
					<div className="dropzone-container">
						<Form.Group controlId="formFile">
							<section className="dropzone">
								{files.length ? (
									<div className="dropzone-input has-files">
										<p className="file">{files[0].name}</p>
										<button
											className="clear-files-btn"
											onClick={() => setFiles([])}
										>
											&times;
										</button>
									</div>
								) : (
									<div
										className="dropzone-input"
										{...getRootProps()}
									>
										<input
											{...getInputProps({
												accept: "video/*",
											})}
										/>
										<p className="desktop-text">
											Drag ’n’ drop a file here, or click
											to select one from your PC.
										</p>
										<p className="mobile-text">
											Tap here to select files from your
											camera roll.
										</p>
									</div>
								)}
							</section>
							<div
								className="form-control is-invalid"
								style={{ display: "none" }}
							></div>
							<Form.Control.Feedback type="invalid">
								{errors.upload}
							</Form.Control.Feedback>
						</Form.Group>
					</div>
					<div className="upload-validation">
						<Form.Group controlId="formBasicAgree">
							<Form.Check
								name="agree"
								type="checkbox"
								value={values.agree}
								onChange={handleChange}
								isInvalid={!!errors.agree}
								label="I am the sole rights holder of the Content, and am over 13 years old"
							/>
						</Form.Group>
						<Form.Group controlId="formBasicAdult">
							<Form.Check
								name="adult"
								type="checkbox"
								value={values.adult}
								onChange={handleChange}
								isInvalid={!!errors.adult}
							>
								<Form.Check.Input
									name="adult"
									type="checkbox"
									value={values.adult}
									onChange={handleChange}
									isInvalid={!!errors.adult}
								/>
								<Form.Check.Label>
									I have read and agree to the{" "}
									<Link
										to="/terms-of-service"
										target="_blank"
										className="tos-link"
									>
										Terms of Service
									</Link>{" "}
									&{" "}
									<Link
										to="/copyright-policy"
										target="_blank"
										className="tos-link"
									>
										Contributor Agreement
									</Link>
								</Form.Check.Label>
								<Form.Control.Feedback type="valid">
									You did it!
								</Form.Control.Feedback>
							</Form.Check>
						</Form.Group>
					</div>
					<Button
						variant="primary"
						type="submit"
						className={
							values.adult && values.agree && files.length
								? "submit-btn"
								: "submit-btn disabled"
						}
						disabled={
							values.adult && values.agree && files.length
								? false
								: true
						}
					>
						<span>Add Details</span>
						<span className="arrow-container">
							<img src={arrow} alt="arrow icon" />
						</span>
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default UploadPageOne;
