import Axios from "axios";

const ipApiKey = "a9c75e242479914ee5c44bc2eea083a79b955364d2e9a88d64340431";

export const authCheck = () => {
	return Axios.get("/api/auth").then((res) => res.data);
};

export const handleLogIn = (
	email,
	password,
	city,
	region,
	country,
	addressIP
) => {
	return Axios.post("/api/auth/login", {
		email,
		password,
		city,
		region,
		country,
		addressIP,
	}).then((res) => res.data);
};

export const adminLogIn = (email, hash, user_id) => {
	return Axios.post("/api/auth/admin-login", {
		email,
		hash,
		user_id,
	}).then((res) => res.data);
};

export const handleRegister = (
	email,
	password,
	city,
	region,
	country,
	addressIP,
	referrer
) => {
	return Axios.post("/api/auth/register", {
		email,
		password,
		city,
		region,
		country,
		addressIP,
		referrer,
	}).then((res) => res.data);
};

export const oAuthSignIn = (
	email,
	accessToken,
	city,
	region,
	country,
	addressIP,
	referrer,
	method
) => {
	return Axios.post("/api/auth/oauth", {
		email,
		accessToken,
		city,
		region,
		country,
		addressIP,
		referrer,
		method,
	}).then((res) => res.data);
};

export const oAuthAgree = (email) => {
	return Axios.post("/api/auth/oauth-agree", {
		email,
	}).then((res) => res.data);
};

export const handleLogOut = () => {
	return Axios.get("/api/auth/logout").then((res) => res.data);
};

export const ipLookUp = () => {
	return Axios.get(`https://api.ipdata.co?api-key=${ipApiKey}`).then(
		(res) => res.data
	);
};

export const checkIfBanned = async (ip_address) => {
	return Axios.post("/api/users/banCheck", {
		ip_address,
	});
};

export const sendReset = (email) => {
	return Axios.post("/api/auth/send-reset", {
		email,
	}).then((res) => res.data);
};

export const submitReset = (password, token) => {
	return Axios.post("/api/auth/submit-reset", {
		password,
		token,
	}).then((res) => res.data);
};
