import React, { useState, useEffect, Fragment } from "react";
import "./YoutubePartner.scss";
import "./Upload.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import arrow from "../images/arrow.png";
import { GoogleAuth } from "google-auth-library";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { useAlert } from "react-alert";
import { createYoutubePartner, getLoginInfo } from "../services/user-requests";

const YoutubePartner = ({ activeUser, setActiveSection }) => {
	const [accountEmail, setAccountEmail] = useState("");
	const [isSubmitting, setSubmitting] = useState(false);
	const [channelObj, setChannelObj] = useState({});
	const [page, setPage] = useState(0);
	const alert = useAlert();
	useEffect(() => {
		getLoginInfo(activeUser.user_id).then((data) => {
			console.log(data);
			if (data.length && data[0].channel_id && data[0].channel_title) {
				setChannelObj({
					channel_id: data[0].channel_id,
					channel_title: data[0].channel_title,
					user_id: activeUser.user_id,
					partner_status: data[0].partner_status,
				});
				setPage(3);
			} else {
				setPage(1);
			}
		});
	}, []);
	const responseGoogle = (response) => {
		console.log(response);
		if (response.profileObj) {
			console.log(response.profileObj.email);
			setAccountEmail(response.profileObj.email);
			axios
				.get(
					"https://www.googleapis.com/youtube/v3/channels?mine=true&part=id,snippet,status,brandingSettings,contentOwnerDetails,auditDetails,statistics",
					{
						headers: {
							Authorization: `Bearer ${response.tokenObj.access_token}`,
						},
					}
				)
				.then((res) => {
					console.log(res.data);
					if (res.data && res.data.items && res.data.items.length) {
						const {
							id,
							brandingSettings,
							snippet,
							status,
							contentOwnerDetails,
						} = res.data.items[0];
						const {
							viewCount,
							videoCount,
							subscriberCount,
						} = res.data.items[0].statistics;
						const {
							overallGoodStanding,
							communityGuidelinesGoodStanding,
							copyrightStrikesGoodStanding,
							contentIdClaimsGoodStanding,
						} = res.data.items[0].auditDetails;
						const channel = {
							user_id: activeUser.user_id,
							channel_id: id,
							channel_title: brandingSettings.channel.title,
							thumbSrc: snippet.thumbnails.default.url,
							viewCount,
							videoCount,
							subscriberCount,
							overallGoodStanding,
							communityGuidelinesGoodStanding,
							copyrightStrikesGoodStanding,
							contentIdClaimsGoodStanding,
							contentOwner: contentOwnerDetails.contentOwner,
							timeLinked: contentOwnerDetails.timeLinked,
							publishedAt: snippet.publishedAt,
							isLinked: status.isLinked,
						};
						console.log(channel);
						setChannelObj(channel);
						setPage(2);
					} else {
						alert.error(
							"No channels found. Please try a different account."
						);
					}
				});
		}
	};
	const handleChannelSubmit = () => {
		setSubmitting(true);
		createYoutubePartner(channelObj).then((res) => {
			if (res.message === "Partnership successfully created") {
				setPage(3);
			}
		});
	};
	return (
		<div className="main-container partner-container upload-form-container">
			<h3>Partner your YouTube channel with Yoon</h3>
			{page === 1 ? (
				<Fragment>
					<h6>Please agree to the following:</h6>
					<Formik
						initialValues={{
							agree: false,
							adult: false,
						}}
						validate={(values) => {
							let errors = {};
							if (!values.agree) {
								errors.agree = "Required";
							}
							if (!values.adult) {
								errors.adult = "Required";
							}
							return errors;
						}}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={(values, { setSubmitting }) => {
							// submitPageOne(files);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Form noValidate onSubmit={handleSubmit}>
								<div className="upload-validation">
									<Form.Group controlId="formBasicAgree">
										<Form.Check
											name="agree"
											type="checkbox"
											value={values.agree}
											onChange={handleChange}
											isInvalid={!!errors.agree}
											label="I am the sole rights holder of the Content, and am over 13 years old"
										/>
									</Form.Group>
									<Form.Group controlId="formBasicAdult">
										<Form.Check
											name="adult"
											type="checkbox"
											value={values.adult}
											onChange={handleChange}
											isInvalid={!!errors.adult}
										>
											<Form.Check.Input
												name="adult"
												type="checkbox"
												value={values.adult}
												onChange={handleChange}
												isInvalid={!!errors.adult}
											/>
											<Form.Check.Label>
												I have read and agree to the{" "}
												<Link
													to="/terms-of-service"
													target="_blank"
													className="tos-link"
												>
													Terms of Service
												</Link>{" "}
												&{" "}
												<Link
													to="/copyright-policy"
													target="_blank"
													className="tos-link"
												>
													Contributor Agreement
												</Link>
											</Form.Check.Label>
											<Form.Control.Feedback type="valid">
												You did it!
											</Form.Control.Feedback>
										</Form.Check>
									</Form.Group>
								</div>
								<div className="submit-area">
									<p>
										*Please note that you must have at least
										1000 subscribers and 4000 watch hours in
										the past 12 months.
									</p>
									<p>
										**When you click the button to submit
										your channel, make sure to select the
										channel you want to partner in the next
										window.
									</p>
									<GoogleLogin
										clientId="775075515388-r8q4bt12jv4dsbmqobuc4io32mh287p1.apps.googleusercontent.com"
										render={(renderProps) => (
											<Button
												variant="primary"
												type="submit"
												className={
													values.adult && values.agree
														? "submit-btn yt-submit"
														: "submit-btn yt-submit disabled"
												}
												onClick={renderProps.onClick}
												disabled={
													(values.adult &&
														values.agree) ||
													renderProps.disabled
														? false
														: true
												}
											>
												<span>
													Submit your YouTube channel
												</span>
												<span className="arrow-container">
													<img
														src={arrow}
														alt="arrow icon"
													/>
												</span>
											</Button>
										)}
										scope="https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtubepartner-channel-audit"
										autoLoad={false}
										onSuccess={responseGoogle}
										onFailure={responseGoogle}
										cookiePolicy={"single_host_origin"}
									/>
								</div>
							</Form>
						)}
					</Formik>
				</Fragment>
			) : page === 2 ? (
				<Fragment>
					<div className="label-area">
						<p>
							We found this channel associated with your account.
							Please confirm that it is yours:
						</p>
					</div>
					<div className="channel-select">
						<div className="channel">
							<img
								src={channelObj.thumbSrc}
								alt="Channel Thumb"
							/>
							<span>{channelObj.channel_title}</span>
						</div>
					</div>
					<div className="channel-btns">
						<Button
							variant="primary"
							type="submit"
							className={
								!isSubmitting
									? "submit-btn"
									: "submit-btn disabled"
							}
							onClick={handleChannelSubmit}
							disabled={isSubmitting}
						>
							<span>Yes, this is my channel</span>
							<span className="arrow-container">
								<img src={arrow} alt="arrow icon" />
							</span>
						</Button>
						<Button
							variant="primary"
							type="submit"
							className={
								!isSubmitting
									? "submit-btn back-btn"
									: "submit-btn back-btn disabled"
							}
							onClick={() => setPage(1)}
							disabled={isSubmitting}
						>
							<span>Go Back</span>
							<span className="arrow-container">
								<img src={arrow} alt="arrow icon" />
							</span>
						</Button>
					</div>
				</Fragment>
			) : page === 3 ? (
				<Fragment>
					<div className="label-area">
						{channelObj.partner_status === "rejected" ? (
							<p>
								Unfornatunately, we are not able to approve your
								partner request at this time.
							</p>
						) : channelObj.partner_status === "approved" ? (
							<p>Your channel is one of our partners!</p>
						) : (
							<p>
								We have successfully received your channel and
								will consider it for our partner program. Please
								check back here in a couple days.
							</p>
						)}
					</div>
					<div className="channel-select">
						<div className="channel">
							<span>{channelObj.channel_title}</span>
						</div>
					</div>
				</Fragment>
			) : null}
		</div>
	);
};

export default YoutubePartner;
