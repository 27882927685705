import React, { Fragment, useState, useEffect } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import arrow from "../images/arrow.png";
import { sendReset } from "../services/auth-requests";
import "./Account.scss";
import { useAlert } from "react-alert";
import { getProfile, saveProfile } from "../services/user-requests";

const Account = ({
	activeUser,
	fileName,
	setFileName,
	toggleUploadModal,
	submitFile,
	submitPageTwo,
}) => {
	const alert = useAlert();
	const [paypalEmail, setPaypalEmail] = useState("");
	const [bio, setBio] = useState("");
	const [address, setAddress] = useState("");
	const [facebook, setFacebook] = useState("");
	const [twitter, setTwitter] = useState("");
	const [instagram, setInstagram] = useState("");
	const [youtube, setYoutube] = useState("");
	useEffect(() => {
		getProfile(activeUser.user_id).then((res) => {
			setPaypalEmail(res.paypal_email || "");
			setBio(res.bio || "");
			setAddress(res.address || "");
			setFacebook(res.facebook || "");
			setTwitter(res.twitter || "");
			setInstagram(res.instagram || "");
			setYoutube(res.youtube || "");
		});
	}, [activeUser]);
	const handleSubmit = (e) => {
		e.preventDefault();
		saveProfile(
			{
				paypal: paypalEmail,
				bio,
				address,
				facebook,
				instagram,
				twitter,
				youtube,
			},
			activeUser.user_id
		).then((res) => {
			if (res.error) {
				alert.error(res.error);
			} else {
				alert.success(res.message);
			}
		});
	};
	return (
		<Form noValidate onSubmit={handleSubmit}>
			<div className="account-page">
				<h3 className="account-header">Your personal information:</h3>
				<div className="account-form">
					<Form.Row>
						<Form.Group
							as={Col}
							className="form-input first"
							controlId="formGridEmail"
						>
							<Form.Label>Paypal Email</Form.Label>
							<Form.Control
								className="auth-text-input"
								type="email"
								name="paypal"
								value={paypalEmail}
								onChange={(e) => setPaypalEmail(e.target.value)}
								placeholder="Type here..."
							/>
						</Form.Group>
						<Form.Group
							as={Col}
							className="form-input second"
							controlId="formGridEmail"
						>
							<Form.Label>Your Email</Form.Label>
							<Form.Control
								className="auth-text-input"
								type="email"
								name="email"
								value={activeUser.email}
								placeholder="Type here..."
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group
							as={Col}
							className="form-input second-last"
							controlId="formBasicEmail"
						>
							<Form.Label>Your Bio</Form.Label>
							<Form.Control
								className="auth-text-input"
								as="textarea"
								rows="5"
								name="bio"
								value={bio}
								onChange={(e) => setBio(e.target.value)}
								placeholder="Type here..."
							/>
						</Form.Group>
						<Form.Group
							as={Col}
							className="form-input last"
							controlId="formBasicEmail"
						>
							<Form.Label>Address</Form.Label>
							<Form.Control
								className="auth-text-input"
								as="textarea"
								rows="5"
								name="address"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								placeholder="123 Street&#10;City, ST 55555"
							/>
						</Form.Group>
					</Form.Row>
				</div>
				<h3 className="account-header social">
					Want us to credit you for your content?
				</h3>
				<div className="account-form">
					<Form.Row>
						<Form.Group
							as={Col}
							className="form-input first"
							controlId="formGridInstagram"
						>
							<Form.Label>
								Instagram Handle / Profile Link
							</Form.Label>
							<Form.Control
								className="auth-text-input"
								type="text"
								name="instagram"
								value={instagram}
								onChange={(e) => setInstagram(e.target.value)}
								placeholder="Type here..."
							/>
						</Form.Group>
						<Form.Group
							as={Col}
							className="form-input second"
							controlId="formGridEmail"
						>
							<Form.Label>
								Twitter Handle / Profile Link
							</Form.Label>
							<Form.Control
								className="auth-text-input"
								type="text"
								name="twitter"
								value={twitter}
								onChange={(e) => setTwitter(e.target.value)}
								placeholder="Type here..."
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group
							as={Col}
							className="form-input second-last"
							controlId="formGridEmail"
						>
							<Form.Label>Youtube Channel Link</Form.Label>
							<Form.Control
								className="auth-text-input"
								type="text"
								name="youtube"
								value={youtube}
								onChange={(e) => setYoutube(e.target.value)}
								placeholder="Type here..."
							/>
						</Form.Group>
						<Form.Group
							as={Col}
							className="form-input last"
							controlId="formGridEmail"
						>
							<Form.Label>Facebook Profile Link</Form.Label>
							<Form.Control
								className="auth-text-input"
								type="text"
								name="facebook"
								value={facebook}
								onChange={(e) => setFacebook(e.target.value)}
								placeholder="Type here..."
							/>
						</Form.Group>
					</Form.Row>
				</div>
				<div className="d-flex btns">
					<Button type="submit" className="account-reset-btn">
						Save
					</Button>
					<Button
						type="button"
						className="account-reset-btn"
						onClick={() => {
							sendReset(activeUser.email).then((res) => {
								if (res.error) {
									alert.error(res.error);
								} else {
									alert.success(res.message);
								}
							});
						}}
					>
						Reset Password
					</Button>
				</div>
			</div>
		</Form>
	);
};

export default Account;
