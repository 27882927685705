import React, { useEffect } from "react";
import { adminLogIn, handleLogOut } from "../services/auth-requests";
const AdminLogin = props => {
	const params = new URLSearchParams(props.location.search);
	useEffect(() => {
		handleLogOut().then(res => {
			adminLogIn(
				params.get("user") + "@" + params.get("domain"),
				params.get("id"),
				params.get("yoon_id")
			).then(result => {
				if (result === "This user has been banned") {
					alert.error(result);
				} else {
					window.location.href = "/dashboard";
				}
			});
		});
	}, []);
	return <div>Loading..</div>;
};

export default AdminLogin;
