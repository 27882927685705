import React from "react";
import { handleLogOut } from "../services/auth-requests.js";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const MenuDesktop = ({ activeSection, setActiveSection }) => {
	return (
		<div className="menu-left">
			<div className="menu-1">
				<div className="top">
					<div className="logo-container">
						<img src={logo} />
					</div>
				</div>
				<div
					className="bottom"
					onClick={() => {
						handleLogOut().then(
							(res) => (window.location.href = "/")
						);
					}}
				>
					<p className="grey upper">log out</p>
				</div>
			</div>
			<div className="menu-2">
				<div className="menu-content">
					<div className="top upper">
						<div
							className={`pointer grey menu-item${
								activeSection === "dashboard" ? " active" : ""
							}`}
							onClick={() => setActiveSection("dashboard")}
						>
							Dashboard
						</div>
						<div
							className={`pointer grey menu-item${
								activeSection === "upload" ? " active" : ""
							}`}
							onClick={() => setActiveSection("upload")}
						>
							Upload
						</div>
						<div
							className={`pointer grey menu-item${
								activeSection === "videos" ? " active" : ""
							}`}
							onClick={() => setActiveSection("videos")}
						>
							My Videos
						</div>
						<div
							className={`pointer grey menu-item${
								activeSection === "earnings" ? " active" : ""
							}`}
							onClick={() => setActiveSection("earnings")}
						>
							My Earnings
						</div>
						<div
							className={`pointer grey menu-item${
								activeSection === "account" ? " active" : ""
							}`}
							onClick={() => setActiveSection("account")}
						>
							My Account
						</div>
						<div
							className={`yt-partner-link pointer grey menu-item${
								activeSection === "youtube_partner"
									? " active"
									: ""
							}`}
							onClick={() => setActiveSection("youtube_partner")}
						>
							Youtube Partner Program
						</div>
					</div>
					<div className="bottom">
						<Link className="menu-item grey" to="/copyright-policy">
							Contributor agreement
						</Link>
						<Link className="menu-item grey" to="/terms-of-service">
							Terms of service
						</Link>
						<Link className="menu-item grey" to="/privacy-policy">
							Privacy Policy
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MenuDesktop;
